
import * as React from 'react';
import { Button, LinearProgress, Typography, withStyles } from '@material-ui/core';
//import {functions} from "../_app"
import { ReactReduxContext, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import ReactCodeInput from 'react-code-input';
import firebaseLib from '../../firebase';
import { User } from '../../model/user';
import { setOnboardMoreInfos } from '../../actions/user/onboarding.action';
import CircularProgress from '@material-ui/core/CircularProgress';
import { cloneDeep } from 'lodash';
import { RmesHttpErrors } from '../../Utils';

export interface CodeRegisterProps {
    user: User;
    setMoreInfosStepper: () => void;
    t: any;
    i18n: any;
}

export interface CodeRegisterState {
    clientCode: string;
    isClientCodeSeemsValid: boolean;
    isServiceCodeSeemsValid: boolean;
    loaderRegistering:boolean;
}

class CodeRegister extends React.Component<CodeRegisterProps, CodeRegisterState> {

    private clientCodeRef: any;
    private clientCodeValue: string;
    private serviceCodeValue: string;


    private numberInput: number = 5;


    constructor(props: CodeRegisterProps) {
        super(props);

        this.state = {
            isClientCodeSeemsValid: false,
            isServiceCodeSeemsValid: false,
            loaderRegistering: false
        }
    }

    // it register the user and return a token
    verifyBothCodes = () => {

        const {
            host, hostname, href, origin, pathname, port, protocol, search
          } = window.location
          

        console.log("client ref", this.clientCodeRef)
        let me = this;

        if (this.verifyCodeIntegrity(this.serviceCodeValue) == false || this.verifyCodeIntegrity(this.clientCodeValue) == false) {

            this.props.enqueueSnackbar(this.props.t('Codes are not valid'), { variant: 'error', autoHideDuration: 2000 });
            return
        }

        var clientCodeVerificationCallable = firebaseLib.functions().httpsCallable('rmesCodeVerify-both');

        //need to convert date to timestamp
        console.log("user sent to cloud function", this.props.user)
        //this.props.user.birthday = this.props.user.birthday.getTime();

        let clonedUser = cloneDeep(this.props.user)
        clonedUser.birthday = this.props.user.birthday.getTime();
        // TODO use a loader => in create account btn ?
        this.setState({ loaderRegistering: true })

        
        clientCodeVerificationCallable({
            clientCode: this.clientCodeValue,
            serviceCode: this.serviceCodeValue,
            user: clonedUser,
            rootUrl: origin + "/",  // https://localhost:3000/ or https://apps.rmes-medical.com/
            smsContentLocalized: me.props.t('Please click on confirmation link ro recover your account')    // recover translated
        })
            .then((result) => {
                console.log('result', result.data)

                let token = result.data.token
                let signedUserData = result.data.user

                console.log("les données à save dans le store serialisés", token, signedUserData)

                me.props.enqueueSnackbar(me.props.t('Codes verified'), { variant: 'success', autoHideDuration: 2000 });




                // set the sign in user forever
                firebaseLib.auth().setPersistence(firebaseLib.auth.Auth.Persistence.LOCAL)
                    .then(function () {

                        firebaseLib.auth().signInWithCustomToken(token)
                            .then((user) => {
                                me.props.setMoreInfosStepper(true)
                                me.props.enqueueSnackbar(me.props.t('Account created'), { variant: 'success', autoHideDuration: 2000 });
                            })
                            .catch((error) => {
                                var errorCode = error.code;
                                var errorMessage = error.message;
                                me.props.enqueueSnackbar(me.props.t('Account creation error'), { variant: 'error', autoHideDuration: 2000 });
                            })

                    })




            }, (error) => {

                if(error.message == RmesHttpErrors.PHONE_EXISTS){
                    console.error("phone already exists at client")
                    me.props.enqueueSnackbar(me.props.t('phone exists'), { autoHideDuration: 15000 , variant: 'error' });
                }else{
                    me.props.enqueueSnackbar(me.props.t('Codes are not valid'), { variant: 'error' });
                    console.error("error at back side", error)
                }

            }).finally(() => {
                this.setState({ loaderRegistering: false })
            })
    }






    private verifyCodeIntegrity(code): boolean {

        //TODO regexp for digits
        if (code != undefined && code.length == this.numberInput) {
            return true;
        }
        return false;
    }

    onClientCodeChange = (value: string) => {
        console.log("code change", value)
        this.clientCodeValue = value;


        this.setState({ isClientCodeSeemsValid: this.verifyCodeIntegrity(this.clientCodeValue) })

    }

    onServiceCodeChange = (value: string) => {
        console.log("service change", value)
        this.serviceCodeValue = value;


        this.setState({ isServiceCodeSeemsValid: this.verifyCodeIntegrity(this.serviceCodeValue) })

    }


    public render() {

        const props = {
            className: "codeInput",
            inputStyle: {
                fontFamily: 'monospace',
                margin: '4px',
                MozAppearance: 'textfield',
                width: '60px',
                borderRadius: '3px',
                fontSize: '30px',
                height: '60px',
                paddingLeft: '7px',
                backgroundColor: 'white',
                textAlign: "center",
                color: 'lightskyblue',
                border: '1px solid lightskyblue'
            },
            inputStyleInvalid: {
                fontFamily: 'monospace',
                margin: '4px',
                MozAppearance: 'textfield',
                width: '15px',
                borderRadius: '3px',
                fontSize: '14px',
                height: '26px',
                paddingLeft: '7px',
                backgroundColor: 'black',
                color: 'red',
                border: '1px solid red'
            }
        }

        return (

            <div style={{ margin: "auto", flex: 1, justifyContent: "center", flexDirection: "row" }}>

                <LinearProgress variant="determinate" value={88} />

                <div style={{ margin: "auto", textAlign: "center" }}>
                    <Typography style={{ textAlign: "center", marginTop: 30 }} component="h5" variant="h5">
                        {this.props.t('The establishment code')}
                    </Typography>
                    <ReactCodeInput onChange={this.onClientCodeChange} ref={(ref) => { this.clientCodeRef = ref }} type='number' fields={this.numberInput} {...props} />
                </div>

                {this.state.isClientCodeSeemsValid == true &&
                    <div style={{ margin: "auto", textAlign: "center" }}>
                        <Typography style={{ textAlign: "center", marginTop: 30 }} component="h5" variant="h5">
                            {this.props.t('The service code')}
                        </Typography>
                        <ReactCodeInput onChange={this.onServiceCodeChange} type='number' fields={this.numberInput} {...props} />
                    </div>
                }

                {this.state.isServiceCodeSeemsValid == true && this.state.isClientCodeSeemsValid == true &&
                    <Button disabled={this.state.loaderRegistering} style={{ display: "block", margin: "auto", marginTop: 40 }} onClick={() => { this.verifyBothCodes() }} variant="contained" color="primary">
                        {this.props.t('Create account')}
                        {this.state.loaderRegistering && <CircularProgress style={{ marginLeft: 10 }} size={24} />}
                    </Button>
                }

            </div>

        );
    }
}


const useStyles = {

}


const mapDispatchToProps = dispatch => ({
    setMoreInfosStepper: (display: boolean) => dispatch(setOnboardMoreInfos(display))
})

const mapStateToProps = state => ({
    user: state.userReducer.user
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(withSnackbar(withTranslation()(CodeRegister)))));
