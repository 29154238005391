import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { deferredPrompt } from '../../App';
import {
    withRouter,
} from "react-router-dom";
import Container from '@material-ui/core/Container';
import { GenreEnum } from '../../model/GenreEnum';
import { User } from '../../model/user';
import { SoftButton } from '../../components/SoftButton';

export interface InstallProps {
    goNext: string;
    history: any;
    t: any;
    i18n: any;
    user: User;
    currentUser: User;
}

class Install extends React.Component<InstallProps, any> {

    constructor(props: InstallProps) {
        super(props)
    }

    next = () => {


        if (deferredPrompt) {

            let me = this;

            deferredPrompt.prompt();


            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    me.props.history.push(me.props.goNext)
                } else {
                    me.props.history.push(me.props.goNext)
                }
            });


        } else {
            console.warn('no defered prompt accessible => go next')
            this.props.history.push(this.props.goNext)
        }
    }


    render() {



        let userGenre: GenreEnum;

        if (this.props.currentUser) { // MUST first check currentUser
            userGenre = this.props.currentUser.genre;
        } else if (this.props.user) {
            userGenre = this.props.user.genre;
        } else {
            userGenre = GenreEnum.MALE;
        }



        return (
            <Container>
                <LinearProgress variant="determinate" value={90} />

                <img style={{ display: "block", borderRadius: 20, margin: "auto", width: "100%", marginTop: 30 }} src={`/img/install_${userGenre.toString()}.png`}></img>

                <Button onClick={this.next} fullWidth variant="contained" style={{ marginTop: 16, marginBottom: 8, display: "block" }}
                    color="primary"
                >
                    {this.props.t('Install RMES')}
                </Button>

                <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                    <SoftButton color="default" onClick={() => { this.props.history.push(this.props.goNext) }}
                        style={{
                            display: "block", textAlign: "center", margin: "auto",
                            bottom: 15, position: "absolute", margin: "auto",
                        }} variant="contained" color="primary">
                        {this.props.t('Skip this step')}
                    </SoftButton>
                </div>


            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        currentUser: state.userReducer.currentUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Install)));
