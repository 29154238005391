"use strict";
// @ts-nocheck
// @ts-ignore
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
exports.__esModule = true;
var relation_1 = require("./relation");
var scenario_1 = require("./scenario");
var json2typescript_1 = require("json2typescript");
var date_converter_1 = require("./converter/date.converter");
var User = /** @class */ (function () {
    function User() {
        this.id = undefined;
        this.createdAt = undefined;
        // registration info needed
        this.firstName = undefined;
        this.lastName = undefined;
        this.phone = undefined;
        this.genre = undefined;
        this.birthday = undefined;
        this.locale = undefined;
        this.oneTransitionOccured = undefined;
        // once register need to fill
        this.room = undefined;
        this.operationDate = undefined;
        this.deleteAccountDate = undefined;
        this.relations = undefined;
        // infos filled at backend side when register => ok
        this.scenarioId = undefined;
        this.serviceId = undefined;
        this.clientId = undefined;
        this.clientName = undefined;
        this.scenario = undefined;
        this.currentState = undefined;
        this.lastStateUpdate = undefined;
        this.operatedTransitions = undefined;
        // to permit relations to onboard on patient app without being connected and see the patient current state (onboard from sms -> static web page + redirect on app )
        this.relationToken = undefined;
    }
    User.prototype.toString = function () {
        return this.getName();
    };
    User.prototype.capitalize = function (str) {
        if (typeof str !== 'string' || str.length === 0) {
            return str;
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    User.prototype.getName = function () {
        return this.capitalize(this.firstName) + " " + this.capitalize(this.lastName);
    };
    User.prototype.getRoomState = function () {
        var _this = this;
        var initState = this.scenario.states.find(function (s) { return s.name == _this.scenario.patient_init_state; });
        if (initState) {
            return initState;
        }
        else {
            throw 'no transit state defined in scenario';
        }
    };
    User.prototype.getTransitState = function () {
        var _this = this;
        var transitState = this.scenario.states.find(function (s) { return s.name == _this.scenario.patient_start_state; });
        if (transitState) {
            return transitState;
        }
        else {
            throw 'no transit state defined in scenario';
        }
    };
    User.prototype.getCanEndState = function () {
        var _this = this;
        var canPatientEndState = this.scenario.states.find(function (s) { return s.name == _this.scenario.patient_can_end_state; });
        if (canPatientEndState) {
            return canPatientEndState;
        }
        else {
            throw 'no can end state defined in scenario';
        }
    };
    User.prototype.havePathComplete = function () {
        if (this.operatedTransitions == undefined) {
            return false;
        }
        // should match on last date ?
        var last = this === null || this === void 0 ? void 0 : this.operatedTransitions[(this === null || this === void 0 ? void 0 : this.operatedTransitions.length) - 1];
        if (last) {
            console.log("havePathComplete", last);
            if (last.from == this.scenario.patient_can_end_state && last.to == this.scenario.patient_init_state) {
                return true;
            }
            else if (last.forceFinishPath == true) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            console.log("no operatedTransition");
            return false;
        }
    };
    User.prototype.setNextState = function (nextWantedStateName) {
        var transition = this.scenario.canTransit(this.currentState, nextWantedStateName);
        if (undefined != transition) {
            this.currentState = nextWantedStateName;
            var newState = this.scenario.states.find(function (s) { return s.name == nextWantedStateName; });
            if (newState) {
                var res = {
                    transition: transition,
                    newState: newState
                };
                return res;
            }
            else {
                throw ('new state not found');
            }
        }
        else {
            throw ('transition forbidden');
        }
    };
    User.prototype.getCurrentStateInstance = function () {
        var _this = this;
        var state = this.scenario.states.find(function (s) { return s.name == _this.currentState; });
        if (state) {
            return state;
        }
        throw "state not present in user's scenario";
    };
    // return the current route the patient see 
    User.prototype.nextRouteToDisplay = function () {
        var infosAndRoutes = [
            {
                field: "room",
                route: "/patient/room/"
            },
            {
                field: "operationDate",
                route: "/patient/operationDate"
            },
            {
                field: "relations",
                route: "/patient/relations"
            },
        ];
        var nextRoute = "/patient/state";
        for (var _i = 0, infosAndRoutes_1 = infosAndRoutes; _i < infosAndRoutes_1.length; _i++) {
            var i = infosAndRoutes_1[_i];
            var infoRoute = i;
            if (this[infoRoute.field] == undefined) {
                nextRoute = infoRoute.route;
                break;
            }
        }
        return nextRoute;
    };
    // return i18n string
    User.prototype.isValidForRegister = function () {
        // return will be translated
        if (!this.firstName) {
            return "Your first name";
        }
        if (!this.lastName) {
            return "Your last name";
        }
        if (!this.birthday) {
            return "Your birthday";
        }
        if (!this.phone) {
            return "Your phone number";
        }
        return true;
    };
    User.prototype.hasRelations = function () {
        return this.relations != undefined && Array.isArray(this.relations) && this.relations.length > 0;
    };
    __decorate([
        json2typescript_1.JsonProperty("id", String, true)
    ], User.prototype, "id");
    __decorate([
        json2typescript_1.JsonProperty("createdAt", date_converter_1.DateTimestampConverter, true)
    ], User.prototype, "createdAt");
    __decorate([
        json2typescript_1.JsonProperty("firstName", String, true)
    ], User.prototype, "firstName");
    __decorate([
        json2typescript_1.JsonProperty("lastName", String, true)
    ], User.prototype, "lastName");
    __decorate([
        json2typescript_1.JsonProperty("phone", String, true)
    ], User.prototype, "phone");
    __decorate([
        json2typescript_1.JsonProperty("genre", Number, true)
    ], User.prototype, "genre");
    __decorate([
        json2typescript_1.JsonProperty("birthday", date_converter_1.DateTimestampConverter, true)
    ], User.prototype, "birthday");
    __decorate([
        json2typescript_1.JsonProperty("locale", String, true)
    ], User.prototype, "locale");
    __decorate([
        json2typescript_1.JsonProperty("oneTransitionOccured", Boolean, true)
    ], User.prototype, "oneTransitionOccured");
    __decorate([
        json2typescript_1.JsonProperty("room", String, true)
    ], User.prototype, "room");
    __decorate([
        json2typescript_1.JsonProperty("operationDate", date_converter_1.DateTimestampConverter, true)
    ], User.prototype, "operationDate");
    __decorate([
        json2typescript_1.JsonProperty("deleteAccountDate", date_converter_1.DateTimestampConverter, true)
    ], User.prototype, "deleteAccountDate");
    __decorate([
        json2typescript_1.JsonProperty("relations", [relation_1.Relation], true)
    ], User.prototype, "relations");
    __decorate([
        json2typescript_1.JsonProperty("scenarioId", String, true)
    ], User.prototype, "scenarioId");
    __decorate([
        json2typescript_1.JsonProperty("serviceId", String, true)
    ], User.prototype, "serviceId");
    __decorate([
        json2typescript_1.JsonProperty("clientId", String, true)
    ], User.prototype, "clientId");
    __decorate([
        json2typescript_1.JsonProperty("clientName", String, true)
    ], User.prototype, "clientName");
    __decorate([
        json2typescript_1.JsonProperty("scenario", scenario_1.Scenario, true)
    ], User.prototype, "scenario");
    __decorate([
        json2typescript_1.JsonProperty("currentState", String, true)
    ], User.prototype, "currentState");
    __decorate([
        json2typescript_1.JsonProperty("lastStateUpdate", date_converter_1.DateTimestampConverter, true)
    ], User.prototype, "lastStateUpdate");
    __decorate([
        json2typescript_1.JsonProperty("operatedTransitions", [scenario_1.TransitionOperated], true)
    ], User.prototype, "operatedTransitions");
    __decorate([
        json2typescript_1.JsonProperty("relationToken", String, true)
    ], User.prototype, "relationToken");
    User = __decorate([
        json2typescript_1.JsonObject("User")
    ], User);
    return User;
}());
exports.User = User;
