import { AppBar, Backdrop, Button, CircularProgress, Container, Divider, IconButton, Switch, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';

import { connect } from 'react-redux';
import { User } from '../../model/user';
import firebaseLib from '../../firebase';
import { State, TransitionDone } from '../../model/scenario';
import { setCurrentUser } from '../../actions/user/user.action';
import {  JsonUtils } from '../../Utils';
import { EventSender, EVENT_TRANSITION_DONE } from '../../model/event';
import firebase from 'firebase';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { ReactSVG } from 'react-svg'
import { withSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';
import { ConfirmChangingStateDialog } from '../../components/confirmChangingState.dialog'
import BottomCurve from '../../assets/svg/bottom2.png'
import Logo from '../../assets/logo-medium.png'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import EventIcon from '@material-ui/icons/Event';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { triggerAsyncId } from 'async_hooks';
import { TransitionProps } from '@material-ui/core/transitions';
import Zoom from '@material-ui/core/Zoom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ButtonBase } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import Hammer from "hammerjs"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});


export interface PatientStatePageProps {
  currentUser: User;
  setCurrentUser: (currentUser: User) => void;
  history: any; //router
  t: any;
  i18n: any;
  enqueueSnackbar: (mesg: string, options: any) => void;
}

export interface PatientStatePageState {
  inTransit: boolean;
  loading: false;
  confirmationStateDialogOpened: boolean;
  confirmState: State | undefined;
  popoverOpened: boolean;
  switcherRef: any;
}

export enum predefinedUserStates {
  ROOM = "ROOM",
  TRANSIT = "TRANSIT"
}


class PatientStatePage extends React.Component<PatientStatePageProps, any> {

  private switcherRef: any;
  private panRef:any;

  constructor(props: PatientStatePageProps) {
    super(props)

    this.switcherRef = React.createRef();

    this.state = {
      loading: false,
      confirmationStateDialogOpened: false,
      confirmState: undefined,
      popoverOpened: false,
    }




  }

  private getSmsContent(nextState: State): string {
    let sentence = this.props.t('TRANSITION_' + nextState.name.toUpperCase(), {
      name: `${this.props.currentUser.firstName} ${this.props.currentUser.lastName}`
    })

    // redirect to a page that explain each step
    let moreInfo = this.props.t("MORE_INFO_ABOUT_TRANSITION", {
      link: process.env.REACT_APP_RMES_RELATION_ABOUT_ROOT_LINK + this.props.currentUser.genre.toString() + "/" + this.props.currentUser.locale + "/" + nextState.name.toLowerCase() + ".html"
    })

    let finalSms = sentence + moreInfo
    console.log("final sms", finalSms)
    return finalSms;
  }



  processTransition = (nextState: State, inTransit: boolean) => {

    var stateChangeCallable = firebaseLib.functions().httpsCallable('rmesStateChange-patient');


    this.setState({ loading: true })


    let finalSms = this.getSmsContent(nextState)

    stateChangeCallable({
      nextStateName: nextState.name,
      userId: this.props.currentUser.id,
      timestamp: new Date().getTime(),
      uiSmsMessage: finalSms
    })
      .then((result: any) => {

        // get data from back
        let transitionInfo: TransitionDone = result.data.transitionInfo;

        // invert the switcher
        this.setState({ inTransit: inTransit })

        // log into GA
        EventSender.transitionDone(transitionInfo, this.props.currentUser)

        // recreate new current User state for redux 
        let newUser = cloneDeep(this.props.currentUser)
        newUser.currentState = transitionInfo.newState.name

        // dispatch current user with new state
        this.props.setCurrentUser(newUser)

        this.setPopoverState()

      }, error => {
        this.props.enqueueSnackbar(this.props.t('Error on update'), { variant: 'error', autoHideDuration: 2000 });
      }).finally((data: any) => {
        this.setState({ loading: false })
      })
  }

  setPopoverState() {
    let transitState = this.props.currentUser.getTransitState()
    let roomState = this.props.currentUser.getRoomState()
    //setTimeout(() => {
      if (this.props.currentUser.currentState == roomState.name) {
        this.setState({ popoverOpened: true })
      } else {
        this.setState({ popoverOpened: false })
      }

    //}, 100);
  }

  componentDidMount() {
    this.setPopoverState()

    this.panRef = new Hammer(this.switcherRef);

    let me = this;
    this.panRef.on("panend", function (ev: any) {
      console.log("panend")

     me.displayConfirmationDialog(undefined, me.props.currentUser ? me.props.currentUser.currentState == predefinedUserStates.ROOM : true)      
    });
  }

  componentWillUnmount(){

  }

  componentDidUpdate(prevProps: PatientStatePageProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.setPopoverState()
    }
  }

  goOnConfiguration = () => {
    this.props.history.push("/patient/configuration")
  }

  goOnRelationPage = () => {
    this.props.history.push("/patient/configuration/relations")
  }

  goOnExitPage = () => {
    this.props.history.push("/patient/exit")
  }

  // 'yes' from confirmation dialog
  onStateChangeConfirmed = (confirmedState: State) => {
    console.log("patient confirmed for", confirmedState.name)
    this.setState({ confirmationStateDialogOpened: false })
    this.processTransition(confirmedState, this.props.currentUser.getTransitState().name == confirmedState.name)
  }

  // 'no' from confirmation dialog
  onStateChangeCancel = () => {
    this.setState({ confirmationStateDialogOpened: false })
  }

  displayConfirmationDialog = (event: any, inTransit: boolean) => {
    let nextState: State | undefined = undefined;

    if (this.props.currentUser.operationDate == undefined) {
      this.props.enqueueSnackbar(this.props.t('No operation date'), { variant: 'warning', autoHideDuration: 3000 });
      return;
    }

    if (this.props.currentUser.relations == undefined || this.props.currentUser.relations.length <= 0) {
      this.props.enqueueSnackbar(this.props.t('You do not have any relations'), { variant: 'warning', autoHideDuration: 3000 });
      return;
    }


    if (inTransit == true) {
      console.log("get TRANSIT for user")
      nextState = this.props.currentUser.getTransitState()
      this.setState({ confirmationStateDialogOpened: true, confirmState: nextState })
    } else {
      console.log("get ROOM for user")

      if (this.props.currentUser.currentState != this.props.currentUser.getCanEndState().name) {
        this.props.enqueueSnackbar(this.props.t('Can not end process'), { variant: 'error', autoHideDuration: 2000 });
        return;
      }

      nextState = this.props.currentUser.getRoomState()
      this.setState({ confirmationStateDialogOpened: true, confirmState: nextState })
    }

    if (!nextState) {
      throw 'switcher state not found'
    }
  }

  private howManyDaysWillAccountWillBeDeleted(): number {
    let todaysDate = moment(new Date());
    let oDate = moment(this.props.currentUser.deleteAccountDate);
    let diffDays = oDate.diff(todaysDate, 'days');
    return diffDays;
  }

  getImageAccordingUserState = () => {
    let imgStaticPath = `/states/${this.props.currentUser.currentState.toLowerCase()}_${this.props.currentUser.genre.toString()}.png`;
    console.log("image stage path", imgStaticPath)
    return imgStaticPath;
  }

  private getCurrentStateSliderIndex(): number {
    return this.props.currentUser.scenario.states.findIndex((st: State) => { return st.name == this.props.currentUser.currentState })
  }



  render() {
    let me = this;
    let currentCarousselImageIndex: number = this.getCurrentStateSliderIndex();
    let carouselRef = undefined;


    console.log("render", this.props.currentUser)
    return (
      <div>


        <AppBar position="static">
          <Toolbar>
            <IconButton onClick={this.goOnConfiguration} edge="start" color="inherit" aria-label="menu">
              <SettingsIcon />
            </IconButton>
            <Typography onClick={this.goOnConfiguration} variant="h6" >
              {this.props.t('Change your informations')}
            </Typography>
          </Toolbar>
        </AppBar>

        {/* your operation date  */}
        <div style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>


          <div style={{ width: "80%", textAlign: "center", justifyContent: "center", marginLeft: "10%" }}>
            {/* current operation date */}
            {this.props.currentUser.operationDate &&
              <div style={{ marginTop: 20 }}>
                <Typography>
                  {this.props.t('Your operation date is')}
                </Typography>

                <div style={{ justifyContent: "center", alignContent: "center", alignItems: "center", display: "flex", flexDirection: "row", flex: 1 }}>
                  <EventIcon color="secondary"> </EventIcon>
                  <Typography variant="h6" > <strong>{moment(this.props.currentUser.operationDate).format('Do MMMM YYYY')}</strong> </Typography>
                </div>
              </div>
            }

            {/* no operation date defined */}
            {this.props.currentUser.operationDate == undefined &&
              <Button  onClick={() => {  this.props.history.push({ pathname: "/patient/configuration/operationDate", state: { goNext: "/patient/state" } }) }} 
              style={{ marginTop: 20, backgroundColor: "#FFAA00" }} variant="contained" color="secondary">
                      <WarningIcon />
                {this.props.t("No operation date")}</Button>
            }



            {/* no operation date defined */}
            { this.props.currentUser.operationDate < moment().startOf('day').toDate()  &&  this.props.currentUser.createdAt.getTime() == this.props.currentUser.lastStateUpdate.getTime() &&
              <Button  onClick={() => {  this.props.history.push({ pathname: "/patient/configuration/operationDate", state: { goNext: "/patient/state" } }) }} 
              style={{ marginTop: 20, backgroundColor: "#FFAA00" }} variant="contained" color="secondary">
                      <WarningIcon />
                {this.props.t("Your operation date seems not valid")}</Button>
            }
          </div>

          {/* where the staff localize you  */}
          <ButtonBase style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center",
           margin: "auto", padding: 30,  marginBottom: 0, paddingBottom: 0 }}
            onClick={() => { carouselRef.moveTo(this.getCurrentStateSliderIndex()) }}  >


            <Typography>
              {this.props.currentUser.oneTransitionOccured == false &&
                this.props.t('You are')
              }

              {this.props.currentUser.oneTransitionOccured == true &&
                this.props.t('Your relations localize you at')
              }
            </Typography>



            <div style={{ justifyContent: "center", alignContent: "center", alignItems: "center", display: "flex", flexDirection: "row", flex: 1 }}>
              <PersonPinCircleIcon color="secondary"></PersonPinCircleIcon>
              <Typography  variant="h6" > <strong>{this.props.t("PATIENT_" + this.props.currentUser.currentState)}</strong> </Typography>
            </div>

          </ButtonBase>



          {/* state image
          <img style={{ display: "block", borderRadius: 20, margin: "auto", width: "80%", marginTop: 30 }}
            src={this.getImageAccordingUserState()}></img>
             */}

          <div style={{ marginTop: 20 }}>
            <Carousel ref={(ref) => carouselRef = ref} selectedItem={currentCarousselImageIndex} showArrows={true} showIndicators={false} showStatus={false} showThumbs={false}>
              {
                this.props.currentUser.scenario.states.map((state: State, index: number) => {
                  let url = `/states/${state.name.toLowerCase()}_${this.props.currentUser.genre.toString()}.png`;


                  //filter: grayscale(1);
                  if (state.name == this.props.currentUser.currentState) {

                    return (<img key={index} src={url} />)
                  } else {

                    return (
                      <img style={{ filter: "grayscale(1)", /* opacity: 0.1 */ }} key={index} src={url} />
                    )
                  }



                })
              }

            </Carousel>
          </div>



          {/* staff location image */}
          {this.state.popoverOpened == false &&
            <img style={{ display: "block", width: "100%", position: "absolute", bottom: 0, left: 0, right: 0, margin: "auto" }}
              src={`/img/communication.png`}></img>
          }


          {/* rounded bottom img */}
          <div>
            <img src={BottomCurve} style={{ width: "100%", position: "absolute", bottom: 0 }} />
          </div>


          {/* state switcher */}
          <div style={{ transform: "scale(2.2)", alignSelf: "center", margin: "auto", textAlign: "center", width: "30%", position: "absolute", bottom: 20, left: 0, right: 0 }}>

            <div
              ref={(ref) => { this.switcherRef = ref }}
            />


            <Popper
              id={"help-popover"}
              open={this.state.popoverOpened}
              anchorEl={this.switcherRef}
              placement={"top"}
              style={{ width: "80%" }}


              modifiers={{
                flip: {
                  enabled: true,
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent',
                }

              }}
              transition>

              {({ TransitionProps }) => (
                <Zoom {...TransitionProps} timeout={350}>
                  <div>
                    <Paper style={{ padding: 20, backgroundColor: "#31ABA9" }}>
                      
                      
                      
                      {/* OPERATION HAS FINISHED */}
                      {this.props.currentUser.deleteAccountDate != undefined &&
                        <div style={{flex:1, flexDirection: "column"}}>
                          <Typography style={{ color: "white" }} > {this.props.t('Activate your location or wait for delete account', { nDays: this.howManyDaysWillAccountWillBeDeleted() })} </Typography>
                         
                         {/*  REDIRECT ON "BYE BYE PAGE" */}
                          <Button onClick={this.goOnExitPage}   style={{marginTop:20, width: "100%" }} variant="contained"  color="primary">JE FERME L'APPLICATION</Button>

                       </div>
                      }


                      {/* NO RELATIONS ADDED & OPERATION HAS NOT FINISHED */}
                      {this.props.currentUser.deleteAccountDate == undefined && (this.props.currentUser?.relations  == undefined || this.props.currentUser?.relations.length <= 0) &&
                      
                      <div style={{flex:1, flexDirection: "column"}}>
                        <Typography style={{ color: "white" }} > {this.props.t('Need to add relations')} </Typography>
                       
                       {/*  REDIRECT ON "BYE BYE PAGE" */}
                        <Button onClick={this.goOnRelationPage}   style={{marginTop:20, width: "100%" }} variant="contained"  color="primary">{this.props.t('Add a relation')}</Button>
                     </div>
                    }


                    {/* AT LEAST ONE RELATION ADDED & OPERATION HAS NOT FINISHED */}
                    {this.props.currentUser.deleteAccountDate == undefined && this.props.currentUser.relations != undefined && this.props.currentUser.relations.length > 0 &&
                      
                      <div style={{flex:1, flexDirection: "column"}}>
                        <Typography style={{ color: "white" }} > {this.props.t('You have done your work')} </Typography>                      
                     </div>
                    }



                    </Paper>
                    <div className="triangle-code"></div>
                  </div>
                </Zoom>
              )}



            </Popper>



          </div>

          {/* loader when changing state */}
          <Backdrop style={{ zIndex: 5000, backgroundColor: "rgba(0,0,0,0.8)" }} open={this.state.loading}>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <img style={{ width: "80%", display: "block", margin: "auto", marginTop: 20 }} src={Logo} />
              <CircularProgress style={{ display: "block", margin: "auto", marginTop: 40 }} color="secondary" />
            </div>
          </Backdrop>

          {/* confirmation state change dialog */}
          <ConfirmChangingStateDialog onClose={this.onStateChangeCancel}
            onConfirm={this.onStateChangeConfirmed}
            confirmState={this.state.confirmState}
            open={this.state.confirmationStateDialogOpened}>

          </ConfirmChangingStateDialog>


        </div>

      </div >


    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.userReducer.currentUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentUser: (currentUser: User) => dispatch(setCurrentUser(currentUser))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(withTranslation()(PatientStatePage))));
