import React from "react";
import ReactDOM from "react-dom";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import France from "../../assets/flags/fr.png";
import England from "../../assets/flags/en.png";
import { useTranslation } from 'react-i18next';
import {changeLocale} from '../../actions/user/i18n.action'
import { useDispatch, useSelector } from 'react-redux'
import { firestoreDb } from '../../firebase';

const countries = [
    {
        label: "England",
        src: England,
        link: " ",
        value: "en"
      },
    {
    label: "France",
    src: France,
    link: " ",
    value: "fr"
  }
  
];

const useStyles = makeStyles(theme => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2)
  },
  formControl: {
    //margin: theme.spacing(5),
    minWidth: 120,
    backgroundColor: "transparent"
  },
  select: {
    textAlign: "center",
    textDecoration: "none"
  }
}));



function getFlagAccordingLocale(locale:string){
  switch (locale) {
    case "en":
      return England;
    case "fr":
      return France  
    default:
      console.error("no flag for locale", locale)
      break;
  }
}

function changeRemoteLocale(locale, userId){
  firestoreDb.collection('user').doc(userId).update({
    locale: locale
  })
}

function FlagSelector() {

  const {t,  i18n } = useTranslation();

  const dispatch = useDispatch()

  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);
  
  const currentLocale = useSelector(state => state.i18nReducer.language.locale)
  const currentUser = useSelector(state => state.userReducer.currentUser)

  console.log("current locale", currentLocale)
  const [country, setCountry] = React.useState(getFlagAccordingLocale(currentLocale));

  const handleChange = (event) => {
    
    let value = event.target.value;

    if(value == England){        
        dispatch( changeLocale("en") )
        if(currentUser){ // can be used in onboarding or in configuration (account registered)
          changeRemoteLocale("en", currentUser.id)
        }
        setCountry(value);
      }else if(value == France){        
        dispatch( changeLocale("fr") )        
        if(currentUser){
          changeRemoteLocale("fr", currentUser.id)
        }
        setCountry(value);
    }


  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


 
  
  //setCountry(currentDisplayedFlag);
  return (

    


    <form autoComplete="off" style={{ textAlign: "center" }}>
      <FormControl  className={classes.formControl}>
        
        <InputLabel  htmlFor="open-select" />
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={country}
          name="country"
          onChange={ handleChange }
          
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null, // This is important to prevent the menu from being cut off
          }}

          inputProps={{
            id: "open-select"
          }}
        >
          {countries.map((option, key) => (
            <MenuItem value={option.src} key={key}>
              <img src={option.src} alt={option.label} />{" "}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
}


export default FlagSelector