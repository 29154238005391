import { combineReducers } from 'redux';
import { persistReducer, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// not persisted
import userReducer from './user/user.reducer';

// persisted
import i18nReducer from './internationalization.reducer';



const persistConfig = {
    key: 'root',
    storage: storage,    
    whitelist: ['i18nReducer']
  }



  const rootReducer = persistCombineReducers(persistConfig, {
    userReducer,
     i18nReducer 
  });

export default rootReducer


/* export default combineReducers({
    userReducer,
 internationalization: persistReducer(rootPersistConfig, internationalizationReducer)
}); */