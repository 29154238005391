"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
exports.__esModule = true;
var json2typescript_1 = require("json2typescript");
var DateTimestampConverter = /** @class */ (function () {
    function DateTimestampConverter() {
    }
    DateTimestampConverter.prototype.serialize = function (date) {
        // save as timestamp
        return date.getTime();
    };
    DateTimestampConverter.prototype.deserialize = function (timestamp) {
        //// multiplied by 1000 so that the argument is in milliseconds, not seconds.
        if (timestamp) {
            if (timestamp.seconds) {
                return new Date(timestamp.seconds * 1000);
            }
            if (timestamp._seconds) {
                return new Date(timestamp._seconds * 1000);
            }
            console.error("error deserialize DateTimestampConverter");
            return new Date();
        }
        else {
            return new Date();
        }
    };
    DateTimestampConverter = __decorate([
        json2typescript_1.JsonConverter
    ], DateTimestampConverter);
    return DateTimestampConverter;
}());
exports.DateTimestampConverter = DateTimestampConverter;
