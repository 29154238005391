import { CHANGE_LOCALE } from "../actions/user/i18n.action"
import { DefaultLocale } from "../i18n"

/* This reducer is persisted */



const initialState = {
    language: {
        locale: DefaultLocale // local choosen by user
    }
}


export default (state = initialState, action: any) => {
    switch (action.type) {

        case CHANGE_LOCALE:

            return {
                ...state,
                language: {
                    ...state.language,
                    locale: action.locale

                }
            }
        default:
            return state
    }

}