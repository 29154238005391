import { Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import * as React from 'react';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Logo from '../../assets/logo-medium.png'
import FlagSelector from '../../components/localization/FlagSelector';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export interface WelcomeProps {
  history: any;
  t: any;
  i18n: any
}

class Welcome extends React.Component<WelcomeProps, any> {



  constructor(props: WelcomeProps) {
    super(props)
  
    this.state = {
      checked: false
    }
  }

  onBegin = () => {
    this.props.history.push('/onboarding/genre')
  }


  render() {
    return (
      <div style={{ alignItems: "center", width: "100%", display: "flex", flex: 1, flexDirection: "column", justifyContent: "center" }}>





        <img style={{ width: "200px", textAlign: "center", display: "block", marginTop: 10, marginBottom: 10 }} src={Logo} />
        
        <img style={{
          
          borderTopLeftRadius:25,borderTopRightRadius: 25,
          display: "block", margin: "auto", width: "100%", marginTop: 0, position: "relative" }} src="/img/landing.jpg"></img>



        <div style={{ marginTop: 20, zIndex: 9999, width: "80%", textAlign: "center", justifyContent: "center" }}>

          <Typography variant="h6" component="h6" style={{color: "#36618f"}}>
            {this.props.t('Baseline')}
          </Typography>







        </div>

        <div style={{ margin: "auto", width: "80%" }}>
          <FlagSelector></FlagSelector>
        </div>

        <div style={{ margin: "auto", width: "80%", textAlign: "center", marginTop: 20 }}>
          <KeyboardArrowDownIcon style={{ fontSize: 35 }} />
        </div>

          <div style={{ paddingLeft: 20, paddingRight: 20}}>
        <FormControlLabel
          value="end"
          control={<Checkbox checked={this.state.checked} onChange={() => this.setState({checked: !this.state.checked})}  color="primary" />}
          label={<div style={{fontSize: 12, fontStyle: "italic"}}>
              
              <div>J'accepte les CGU et RGPD</div>

              <a href="/cgu/rgpd.html" style={{textAlign: "center"}}>Consulter</a>

            </div>}
          labelPlacement="end"
        />
          
        </div>

        <Button disabled={!this.state.checked}  style={{ textAlign: "center", margin: "auto", marginTop: 40, display: "block", marginBottom: 40 }} onClick={this.onBegin} variant="contained" color="primary">
          {this.props.t('Begin')}
        </Button>



      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Welcome)));
