import firebase from 'firebase/app'
import "firebase/database"
import "firebase/functions"
import "firebase/auth"
import "firebase/firestore"
import "firebase/analytics"

const config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DB,
    projectId: process.env.REACT_APP_PID,
    storageBucket: process.env.REACT_APP_SB,
    messagingSenderId: process.env.REACT_APP_SID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_APP_MEASUREMENT_ID
   
};
console.log("test env",process.env.REACT_APP_TEST)
console.log("initialize firebase with config", config)
let app = firebase.initializeApp(config);
app.analytics();

export const firestoreDb = firebase.firestore(app);

//const databaseRef = firebase.database().ref();
//export const todosRef = databaseRef.child("todos")
export default firebase;