
export enum QuestionType{
    RADIO="radio",
    CHECKBOX="checkbox"
}

export enum IQuestionColor{
    GREEN="green",
    ORANGE="orange",
    RED="red"
}

export interface IQuestionResponse{
    id:string;
    response:string;    
    color:IQuestionColor;
    selected:boolean;
}

export interface ISurveyQuestion{
    type:QuestionType
    question:string;
    responses:IQuestionResponse[]
}

export interface ISurveyReferential{
    clientId:string // can be "default"
    elements:ISurveyQuestion[]
}


