import { IQuestionColor, IQuestionResponse, ISurveyQuestion } from "./SurveyReferential";
import {max} from 'lodash'

export interface IUserSurvey {
    clientId:string;
    userId: string;
    firstName: string;
    lastName: string;
    birthday: Date;
    operationDate: Date;

    elements: ISurveyQuestion[]

    accessToken: string;

    answered: boolean;


}

export interface IUserSurveyAction {
    contact: boolean;
    color: IQuestionColor;
}

export interface IColorsCounters {
    red: number;
    green: number;
    orange: number;
    finalColor:IQuestionColor
}


export function countSurveyColors(survey: IUserSurvey): IColorsCounters {

    let initialValue: IColorsCounters = {
        red: 0,
        orange: 0,
        green: 0
        
    }

    const agregated = survey.elements.reduce(
        (accumulator, element) => {
            
            (element as ISurveyQuestion).responses.forEach((r:IQuestionResponse) => {
                if(r.color == IQuestionColor.GREEN && r?.selected == true){
                    accumulator.green += 1
                }
                if(r.color == IQuestionColor.RED && r?.selected == true){
                    accumulator.red += 1
                }
                if(r.color == IQuestionColor.ORANGE && r?.selected == true){
                    accumulator.orange += 1
                }


            })
            

            return accumulator
        },
        initialValue
    );

    
    

    if(agregated.red > 0){        
        return {
            ...agregated,
            finalColor: IQuestionColor.RED
        }
    }


    if(agregated.orange > 0){        
        return {
            ...agregated,
            finalColor: IQuestionColor.ORANGE
        }
    }


    if(agregated.green >= 0){        
        return {
            ...agregated,
            finalColor: IQuestionColor.GREEN 
        }
    }


    throw "should never happen color seem  priority rules are not correct"


}

export function patientMustBeContacted(survey: IUserSurvey): boolean {

    let mustBeContacted = false;

    for (let d of survey.elements) {
        let element: ISurveyQuestion = d;

        let found: IQuestionResponse | undefined = element.responses.find((e: IQuestionResponse) => {
            if (e.selected == true) {
                if (e.color == IQuestionColor.RED || e.color == IQuestionColor.ORANGE) {
                    return true;
                }
            }
        })

        if (found != undefined) {
            mustBeContacted = true
            break;
        }
    }

    return mustBeContacted


}

