import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { State } from '../model/scenario';
import DialogActions from '@material-ui/core/DialogActions';

import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import Zoom from '@material-ui/core/Zoom';
import { TransitionProps } from '@material-ui/core/transitions';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

export interface confirmChangingStateDialogProps {
    open: boolean;
    confirmState: State;    
    onConfirm: (stateConfirmed: State) => void;
    onClose: () => void;
    children:never[] // <= for transition
}



export function ConfirmChangingStateDialog(props: confirmChangingStateDialogProps) {
    const classes = useStyles();
    const { onClose, open } = props;
    const { t, i18n } = useTranslation();

    const handleClose = () => {
        onClose();
    };


    return (
        <Dialog TransitionComponent={Transition} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{ props.confirmState && t("LOCATION_STATE_" + props.confirmState.name)}</DialogTitle>

            <Typography style={{     textAlign: "left",     marginLeft: "5%",        marginRight: "5%" }}>
                {props.confirmState && t("ACTION_STAFF_LOCALIZE_YOU_" + props.confirmState.name)}
            </Typography>


            <Typography style={{     textAlign: "center", marginTop: 30,     marginLeft: "5%",        marginRight: "5%" }}>
                {props.confirmState && t("You will be localized")}
            </Typography>

            {/* <Paper style={{ margin: 20, backgroundColor: "#31ABA9" }}> */}
            
            <Typography style={{ flexDirection: "row", display: "flex", flex:1, justifyContent: "center",  marginTop: 30, marginBottom:30,  textAlign: "center",     marginLeft: "5%",        marginRight: "5%" }}>
                <PersonPinCircleIcon color="secondary"></PersonPinCircleIcon> 
                {props.confirmState && t("PATIENT_" + props.confirmState.name)}
            </Typography>
            

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button onClick={() => { props.onConfirm(props.confirmState) }} color="primary" autoFocus>
                    {t("I confirm")}
                </Button>
            </DialogActions>


        </Dialog>
    );
}