import { AppBar, Button, Container, Dialog, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, TextField, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';

import { connect } from 'react-redux';
import { logger } from 'workbox-core/_private';
import { setCurrentUser } from '../../actions/user/user.action';
import { Relation } from '../../model/relation';
import { User } from '../../model/user';
import DeleteIcon from '@material-ui/icons/Delete';
import { withSnackbar } from 'notistack';
import firebase, { firestoreDb } from '../../firebase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import AddRelation from './AddRelation';
import CloseIcon from '@material-ui/icons/Close';
import { cloneDeep } from 'lodash';
import SaveIcon from '@material-ui/icons/Save';
import { withTranslation } from 'react-i18next';
import { GenreEnum } from '../../model/GenreEnum';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AsYouType, parsePhoneNumberWithError, ParseError, PhoneNumber } from 'libphonenumber-js/max'
import ErrorIcon from '@material-ui/icons/Error';
import { SoftButton } from '../../components/SoftButton';
import { compact } from 'lodash'
export interface RelationsPageProps {
  setCurrentUser: (currentUser: User) => void
  currentUser: User;
  enqueueSnackbar: (mesg: string, options: any) => void;
  history: any;
  displayBack: any;
  goNext: string;
  skip: boolean;
  user: any;
  t: any;
  i18n: any;
}



export interface RelationsPageState {
  relations: Relation[]
  contactApiSupported: boolean;
  manualWidget: any[]
  displayAddContactDialog: boolean;
}


class RelationsPage extends React.Component<RelationsPageProps, any> {

  static defaultProps = {
    skip: false
  }

  constructor(props) {
    super(props)
    this.state = {
      relations: [],
      initialRelations: [],
      contactApiSupported: false,
      manualWidget: [],
      displayAddContactDialog: false
    }
  }

  componentDidMount() {

    console.log("RelationsPage componentDidMount")

    const contactApiSupported = ('contacts' in navigator && 'ContactsManager' in window);
    let relations = cloneDeep(this.props.currentUser.relations != undefined ? this.props.currentUser.relations : [])
    this.setState({ initialRelations: cloneDeep(relations), contactApiSupported: contactApiSupported, relations: relations })
  }

  next = () => {
    let me = this;
    let user: User = this.props.currentUser;


    // get only valid numbers
    let validRelations = compact(me.state.relations.map((r: any) => {

      try {
        const phoneNumber: PhoneNumber = parsePhoneNumberWithError("+" + r.phone)

        let numberType = phoneNumber.getType();
        if (numberType == "MOBILE") {
          return Object.assign({}, r)
        } else {
          return undefined;
        }
      } catch (error) {
        return undefined
      }


    }));


    firestoreDb.collection('user').doc(user.id).update({
      relations: validRelations
    }).then(function () {

      user.relations = validRelations;
      me.props.setCurrentUser(user) // means dispatch      

      if (validRelations.length == 0) {
        me.props.enqueueSnackbar(me.props.t('Contact(s) saved') + ' (' + validRelations.length + ')', { variant: 'error', autoHideDuration: 2000 });
      } else {
        me.props.enqueueSnackbar(me.props.t('Contact(s) saved') + ' (' + validRelations.length + ')', { variant: 'success', autoHideDuration: 2000 });
      }

      me.props.history.push(me.props.goNext)
    }).catch(function (error) {
      // The document probably doesn't exist.
      me.props.enqueueSnackbar(me.props.t("Error on update"), { variant: 'error', autoHideDuration: 2000 });
      console.error("Error updating document: ", error);
    });





  }

  public async addRelation() {
    if (this.state.contactApiSupported == true) {
      console.log("addRelation() contact api supported")
      try {
        const props = ['name', 'tel'];
        const opts = { multiple: true };
        const contacts: any[] = await navigator.contacts.select(props, opts)

        // remove all contacts with no number in it
        let filteredWithNumber: any[] = contacts.filter((contact: any) => {
          return contact.tel != undefined && contact.tel.length > 0
        })




        let contactsWithFirstNumber: Relation[] = filteredWithNumber.map((c) => {
          let relation: Relation | undefined = new Relation()

          // ugly trick to make works all phone number coming from user directory 
          // we suppose all numbers are FRENCH
          // TODO next version => improve for all countries


          // number is never undefined because of upper filter
          let untrustedNumber = c.tel[0]

          relation.phone = Object.assign("", untrustedNumber);


          if (c.name == undefined) {
            relation.name = this.props.t('Name not present in directory')
          } else {
            relation.name = c.name[0];
          }



          // TODO change for all country (need display flag then check number ...)
          console.warn("ONLY FR MODE")


          if (untrustedNumber[0] == "0") {

            //the input seems "0*" instead 33*
            // => change it manually

            // remove begin 0 
            untrustedNumber = c.tel[0].substring(1);
            // add fr indicatif // TODO should ask for flag to choose the "indicatif"
            untrustedNumber = "33" + untrustedNumber;
          }

          if (untrustedNumber[0] == "+") {
            untrustedNumber = c.tel[0].substring(1);
          }


          relation.phone = untrustedNumber;
          return relation;

        })

        this.setState({ relations: this.state.relations.concat(contactsWithFirstNumber) })


        console.log("contacts fetched", contactsWithFirstNumber)

      } catch (ex) {
        console.error("addRelation() error")
        console.error(ex)
        // Handle any errors here.        
        this.setState({ displayAddContactDialog: true, contactApiSupported: false })
      }
    } else {
      console.log("display manual widget")

      this.setState({ displayAddContactDialog: true })
    }


  }

  deleteContact = (relationToDelete: any) => {
    this.setState({ relations: this.state.relations.filter(r => r != relationToDelete) })
  }

  simulateAddContact = () => {
    let user: User = this.props.currentUser;
    user.relations = [Object.assign(new Relation(), { phone: "06...", name: "copain" })]

    // TODO : use json2typescript
    this.props.setCurrentUser(Object.assign(new User(), user)) // dispatch
    this.props.history.push(this.props.goNext)
  }


  addContact = () => {
    console.log("addContact()")
    this.addRelation();
  }

  onContactAdded = (newRelation: Relation) => {
    console.log("new relation is added ", newRelation)

    this.state.relations.push(newRelation)
    let relations = cloneDeep(this.state.relations)
    this.setState({ relations: relations, displayAddContactDialog: false })
  }

  openManualAdd = () => {
    this.setState({ displayAddContactDialog: true })
  }



  //   <div>{ JSON.stringify(this.state.relations) }</div>
  render() {

    let userGenre: GenreEnum;

    if (this.props.currentUser) { // MUST first check currentUser
      userGenre = this.props.currentUser.genre;
    } else if (this.props.user) {
      userGenre = this.props.user.genre;
    } else {
      userGenre = GenreEnum.MALE;
    }

    console.log("render() contactApiSupported", this.state.contactApiSupported )
    let addRelationBtnText = this.state.contactApiSupported == true ? this.props.t('Add a relation from contacts') : this.props.t('Add a relation manually');




    return (
      <div>

        {this.props.displayBack == true &&
          <AppBar position="static">
            <Toolbar style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>


              <IconButton onClick={this.next} edge="start" color="inherit" aria-label="menu">
                <ArrowBackIcon />
              </IconButton>

              <Typography variant="h6" >
                {this.props.t('Your relations')}
              </Typography>



              <Button onClick={this.openManualAdd} variant="contained" color="secondary" size="small" startIcon={<PersonAddIcon />}>
                manuellement
              </Button>

            </Toolbar>
          </AppBar>
        }

        {/* ADD CONTACT DIALGO (BACKPORT WHEN HTML5 API NOT SUPPORTER) */}
        <Dialog fullScreen open={this.state.displayAddContactDialog} >
          <AppBar>
            <Toolbar>
              <IconButton onClick={() => { this.setState({ displayAddContactDialog: false }) }} edge="start" color="inherit" aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container>
            <Toolbar>{/* content */}</Toolbar>
            <AddRelation style={{ position: "relative" }} onContactsAdded={(newRelation: Relation) => { this.onContactAdded(newRelation) }}></AddRelation>
          </Container>
        </Dialog>

        <img style={{ display: "block", borderRadius: 20, margin: "auto", height: "50%", width: "100%", marginTop: 30 }} src={`/img/relations_${userGenre.toString()}.png`}></img>


        {/* FINAL LIST */}
        <List style={{ width: "80%", marginLeft: "10%" }}>
          {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation: Relation, index: number) => {


            try {
              const phoneNumber: PhoneNumber = parsePhoneNumberWithError("+" + relation.phone)
              console.log("phone number", phoneNumber)

              let numberType = phoneNumber.getType();


              if (numberType == 'MOBILE') {
                return (
                  <Container key={"contact-" + index.toString()}>
                    <ListItem>

                      <ListItemText primary={relation.name} secondary={relation.phone} />
                      <ListItemText secondary={phoneNumber.getType()} />

                      <ListItemSecondaryAction>
                        <IconButton onClick={() => { this.deleteContact(relation) }} edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>

                    </ListItem>
                    <Divider />
                  </Container>
                )
              } else {
                throw 'not mobile'
              }


            } catch (error) {

              return (
                <Container key={"contact-" + index.toString()}>
                  <ListItem>

                    <ListItemText style={{ color: "red" }} primary={relation.name} secondary={this.props.t('Not mobile number')} />

                    <ListItemSecondaryAction>

                      <IconButton onClick={() => { this.deleteContact(relation) }} edge="end" aria-label="delete">
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    </ListItemSecondaryAction>

                  </ListItem>
                  <Divider />
                </Container>

              )

            }

          })
          }
        </List>


        {/* ADD RELATION BTN */}

        <Button style={{ display: "block", margin: "auto" }} onClick={() => this.addContact() } variant="contained" color="primary">
          {addRelationBtnText}
        </Button>


        <div style={{
          bottom: 10,
          flexDirection: "column",
          display: "flex", flex: 1, justifyContent: "center",
          height: "7%",
          width: "100%", margin: "auto", textAlign: "center",
          marginTop: 20,
          marginBottom: 20
        }}>


          {/* means not coming from onboarding stepper but from 'configuration */}
          {this.props.skip == false &&
            <div>
              <Button variant="contained" color="secondary"
                onClick={this.next} startIcon={<SaveIcon />} style={{ margin: "auto" }} >
                {this.props.t('Back')}
              </Button>
            </div>
          }

          {/* means coming from onboarding stepper -> we are sure relations is empty at page mount*/}
          {/* display save boutton only if there is at least one relation */}
          {this.props.skip == true && this.state.relations.length > 0 &&
            <div>
              <Button variant="contained" color="secondary"
                onClick={this.next} startIcon={<SaveIcon />} style={{ margin: "auto" }} >
                {this.props.t('Save')}
              </Button>
            </div>
          }


          {/* can skip the step to go to next stepper page */}
          {this.props.skip == true &&

            <SoftButton color="default" onClick={() => { this.props.history.push(this.props.goNext) }}
              style={{
                display: "block", textAlign: "center", margin: "auto", marginTop: 20, marginBottom: 20,

              }} variant="contained" color="primary">
              {this.props.t('Skip this step')}
            </SoftButton>

          }
        </div>



      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.userReducer.currentUser,
    user: state.userReducer.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentUser: (currentUser: User) => dispatch(setCurrentUser(currentUser))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(withTranslation()(RelationsPage))));
