"use strict";
// @ts-nocheck
// @ts-ignore
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
exports.__esModule = true;
var json2typescript_1 = require("json2typescript");
var date_converter_1 = require("./converter/date.converter");
var StateView = /** @class */ (function () {
    function StateView() {
        this.locale = undefined;
        this.patient_read = undefined;
        this.pro_read = undefined;
    }
    __decorate([
        json2typescript_1.JsonProperty("locale", String, true)
    ], StateView.prototype, "locale");
    __decorate([
        json2typescript_1.JsonProperty("patient_read", String, true)
    ], StateView.prototype, "patient_read");
    __decorate([
        json2typescript_1.JsonProperty("pro_read", String, true)
    ], StateView.prototype, "pro_read");
    StateView = __decorate([
        json2typescript_1.JsonObject("StateView")
    ], StateView);
    return StateView;
}());
exports.StateView = StateView;
var State = /** @class */ (function () {
    function State() {
        this.name = undefined;
        this.view = undefined;
    }
    State.prototype.toString = function () {
        return "name:" + this.name;
    };
    State.prototype.getPatientTranslation = function (locale) {
        if (locale === void 0) { locale = 'fr'; }
        var view = this.view.find(function (v) {
            return v.locale == locale;
        });
        if (view) {
            return view.patient_read;
        }
        else {
            console.error('no translation found');
            return "untranslated";
        }
    };
    __decorate([
        json2typescript_1.JsonProperty("name", String, true)
    ], State.prototype, "name");
    __decorate([
        json2typescript_1.JsonProperty("view", [StateView], true)
    ], State.prototype, "view");
    State = __decorate([
        json2typescript_1.JsonObject("State")
    ], State);
    return State;
}());
exports.State = State;
var StateOptions = /** @class */ (function (_super) {
    __extends(StateOptions, _super);
    function StateOptions() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.canForceFinishPath = false;
        _this.operateForceFinishPath = false;
        return _this;
    }
    __decorate([
        json2typescript_1.JsonProperty("canForceFinishPath", Boolean, true)
    ], StateOptions.prototype, "canForceFinishPath");
    __decorate([
        json2typescript_1.JsonProperty("operateForceFinishPath", Boolean, true)
    ], StateOptions.prototype, "operateForceFinishPath");
    StateOptions = __decorate([
        json2typescript_1.JsonObject("StateOptions")
    ], StateOptions);
    return StateOptions;
}(State));
exports.StateOptions = StateOptions;
var Transition = /** @class */ (function () {
    function Transition() {
        this.from = undefined;
        this.to = undefined;
        this.sendSms = undefined;
        this.canForceFinishPath = undefined; // 'ambulatoire use case' when pro user want to close the path and insert statistics etc
    }
    __decorate([
        json2typescript_1.JsonProperty("from", String, true)
    ], Transition.prototype, "from");
    __decorate([
        json2typescript_1.JsonProperty("to", String, true)
    ], Transition.prototype, "to");
    __decorate([
        json2typescript_1.JsonProperty("sendSms", Boolean, true)
    ], Transition.prototype, "sendSms");
    __decorate([
        json2typescript_1.JsonProperty("canForceFinishPath", Boolean, true)
    ], Transition.prototype, "canForceFinishPath");
    Transition = __decorate([
        json2typescript_1.JsonObject("Transition")
    ], Transition);
    return Transition;
}());
exports.Transition = Transition;
var TransitionOperated = /** @class */ (function (_super) {
    __extends(TransitionOperated, _super);
    function TransitionOperated() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.date = undefined;
        _this.forceFinishPath = false;
        return _this;
    }
    __decorate([
        json2typescript_1.JsonProperty("date", date_converter_1.DateTimestampConverter, true)
    ], TransitionOperated.prototype, "date");
    __decorate([
        json2typescript_1.JsonProperty("forceFinishPath", Boolean, true)
    ], TransitionOperated.prototype, "forceFinishPath");
    TransitionOperated = __decorate([
        json2typescript_1.JsonObject("TransitionOperated")
    ], TransitionOperated);
    return TransitionOperated;
}(Transition));
exports.TransitionOperated = TransitionOperated;
var Scenario = /** @class */ (function () {
    function Scenario() {
        this.name = undefined;
        this.patient_init_state = undefined;
        this.patient_start_state = undefined;
        this.patient_can_end_state = undefined;
        this.hasTransitions = undefined;
        this.states = undefined;
        this.transitions = undefined;
    }
    Scenario.prototype.canTransit = function (fromStateName, toStateName) {
        return this.transitions.find(function (t) {
            return t.from == fromStateName && t.to == toStateName;
        });
    };
    Scenario.prototype.getAccessibleStates = function (fromState) {
        var _this = this;
        var transitionPossibles = this.transitions.filter(function (t) {
            return t.from == fromState;
        });
        console.log("transitions accessibles", transitionPossibles);
        var states = transitionPossibles.map(function (transition) {
            var state = _this.states.find(function (t) { return t.name === transition.to; });
            if ((transition === null || transition === void 0 ? void 0 : transition.canForceFinishPath) == true) {
                state.canForceFinishPath = true;
            }
            return state;
        });
        console.log("states possibles", states);
        return states;
    };
    __decorate([
        json2typescript_1.JsonProperty("name", String, true)
    ], Scenario.prototype, "name");
    __decorate([
        json2typescript_1.JsonProperty("patient_init_state", String, true) // the switcher "off" state (ROOM)
    ], Scenario.prototype, "patient_init_state");
    __decorate([
        json2typescript_1.JsonProperty("patient_start_state", String, true) // the swither "on" state (TRANSIT)
    ], Scenario.prototype, "patient_start_state");
    __decorate([
        json2typescript_1.JsonProperty("patient_can_end_state", String, true) // the swither "on" state (TRANSIT)
    ], Scenario.prototype, "patient_can_end_state");
    __decorate([
        json2typescript_1.JsonProperty("hasTransitions", Boolean, true)
    ], Scenario.prototype, "hasTransitions");
    __decorate([
        json2typescript_1.JsonProperty("states", [State], true)
    ], Scenario.prototype, "states");
    __decorate([
        json2typescript_1.JsonProperty("transitions", [Transition], true)
    ], Scenario.prototype, "transitions");
    Scenario = __decorate([
        json2typescript_1.JsonObject("Scenario")
    ], Scenario);
    return Scenario;
}());
exports.Scenario = Scenario;
