"use strict";
exports.__esModule = true;
var json2typescript_1 = require("json2typescript");
// warning do not link to any libs because used from cloud functions
var JsonUtils = /** @class */ (function () {
    function JsonUtils() {
    }
    JsonUtils.getJsonConvert = function () {
        var jsonConvert = new json2typescript_1.JsonConvert();
        jsonConvert.operationMode = json2typescript_1.OperationMode.ENABLE; // print some debug data
        jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
        jsonConvert.valueCheckingMode = json2typescript_1.ValueCheckingMode.ALLOW_NULL; // allow null
        return jsonConvert;
    };
    return JsonUtils;
}());
exports.JsonUtils = JsonUtils;
var RmesHttpErrors;
(function (RmesHttpErrors) {
    RmesHttpErrors["PHONE_EXISTS"] = "PHONE_EXISTS";
    RmesHttpErrors["TOKEN_INVALID"] = "TOKEN_INVALID";
})(RmesHttpErrors = exports.RmesHttpErrors || (exports.RmesHttpErrors = {}));
