import { createStore } from 'redux';
import { persistStore } from 'redux-persist';

import rootReducer from "./reducers/root.reducer"

console.log("create store")

export default function configureStore() {
  console.log("configureStore()")

  let store = createStore(rootReducer, /* preloadedState, */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  persistStore(store);

  return store;
}