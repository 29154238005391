import { Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import { IQuestionResponse, ISurveyQuestion } from "../../model/Survey/SurveyReferential";
import { capitalize } from 'lodash';

interface RadioSurveyProps {
    question: ISurveyQuestion | undefined;
    containerStyle: any;
    onValueChange: () => void;
    readonly:boolean;
    displayColor:boolean;
}

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',

        color: "red",
        backgroundColor: 'red',


    },

    red:{
        backgroundColor: "#ff6961"
    },
    green:{
        backgroundColor: "#77DD77"
    },
    orange:{
        backgroundColor: "#f9a976"
    },

    checkedIcon: {
        
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },

    },
});



/* props data are modified in this component */
export const RadioSurveyElement = (props: RadioSurveyProps) => {

    const classes = useStyles()


    const [question, setQuestion] = useState<ISurveyQuestion | undefined>(undefined)
    const [value, setValue] = React.useState<string | undefined>(undefined);
    const [readOnly, setReadonly] = React.useState<boolean>(false);

    useEffect(() => {
      setReadonly(props.readonly)        
    }, [props.readonly])
    

    useEffect(() => {
        if (props.question) {

            // find selected if loaded ever answered 
            let selected = props.question.responses.find((e:IQuestionResponse) => {
                return e.selected == true
            })

            if(selected){
                setValue(selected.id)
            }

            setQuestion(props.question)
        }
    }, [])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("event", event.target.value)

        let idSelected = (event.target as HTMLInputElement).value
        setValue(idSelected);

        props.question?.responses.forEach((r: IQuestionResponse) => {
            if (r.id == idSelected) {
                r.selected = true
            } else {
                r.selected = false
            }
        })

        props.onValueChange()

    };


    return (

        <Container style={props.containerStyle}>

            {question != undefined &&

                <FormControl style={{ marginTop: 20 }}>

                    <FormLabel id="demo-radio-buttons-group-label">{capitalize(question.question)}</FormLabel>

                    <RadioGroup
                        style={{ marginTop: 10 }}
                        aria-labelledby="one radio question"
                        name="radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >

                        {
                            question.responses.map((r: IQuestionResponse) => {

                                let additionalProps = {}

                                if(props?.displayColor == true){
                                    
                                    additionalProps = {
                                        checkedIcon: <span className={`${classes.icon} ${classes.checkedIcon} ${classes[r.color]}`}/>
                                    }
                                }

                                return (
                                    <FormControlLabel color="red" disabled={readOnly}  key={r.id} value={r.id} 
                                    control={    <Radio  {...additionalProps} />}      
                                     label={r.response} />
                                )
                            })
                        }

                    </RadioGroup>

                </FormControl>
            }

        </Container>
    )
};

export default RadioSurveyElement;
