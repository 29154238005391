import * as React from 'react';


import {
    BrowserRouter,
    withRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import CodeRegister from './onboard/CodeRegister';
import Genre from './onboard/Genre';
import Info from './onboard/Info';
import Welcome from './onboard/Welcome';
import { CircularProgress, Container, TextField, Typography } from '@material-ui/core';
import firebaseLib from '../firebase';
import { RmesHttpErrors } from '../Utils';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import Logo from '../assets/logo-medium.png';

export interface RecoverProps {
    history: any;
    t: any;
    enqueueSnackbar: any;
}

export interface RecoverState {
    loading: false;
}


class RecoverLinkScreen extends React.Component<RecoverProps, RecoverState> {

    private token: string | null = undefined;
    private clientId: string | null = undefined;


    constructor(props: RecoverProps) {
        super(props);
        const {
            host, hostname, href, origin, pathname, port, protocol, search
        } = window.location


        console.log("data for recover", search)
        const urlParams = new URLSearchParams(search);

        this.token = urlParams.get('token')
        this.clientId = urlParams.get('clientId')

        this.state = {
            loading: false
        }

    }

    componentDidMount(): void {
        let me = this;
        this.verifyLink().then(() => {
            // we must redirect to home because with https://host/recover/
            // the firebase auth listener for authentication is not registered when home -> this screen redirection is done
            
            setTimeout(() => {
                console.log("[token valid] history go to home")
                window.location.href = "/";    
            }, 2500); // wait for toaster
            
        })
    }

    verifyLink(): Promise<any> {
        var clientCodeVerificationCallable = firebaseLib.functions().httpsCallable('rmesRecoverLinkVerify-check');

        if (!this.token || !this.clientId) {
            console.error("token or clientId is null should never happen")
        }

        this.setState({ loading: true })
        let me = this;

        return clientCodeVerificationCallable({
            token: this.token,
            clientId: this.clientId
        }).then((result) => {
            let token = result.data.token
            let signedUserData = result.data.user

            console.log("les données à save dans le store serialisés", token, signedUserData)
            this.props.enqueueSnackbar(this.props.t('Codes verified'), { variant: 'success', autoHideDuration: 2000 });



            // set the sign in user forever
            return firebaseLib.auth().setPersistence(firebaseLib.auth.Auth.Persistence.LOCAL)
                .then(function () {

                    return firebaseLib.auth().signInWithCustomToken(token)
                        .then((user) => {
                            me.props.enqueueSnackbar(me.props.t('Account recover'), { variant: 'success', autoHideDuration: 2000 });
                            return
                        })
                })


        }, (error) => {

            if (error.message == RmesHttpErrors.TOKEN_INVALID) {
                console.error("recover token invalid")
                me.props.enqueueSnackbar(me.props.t('Invalid recover token'), { autoHideDuration: 5000, variant: 'error' });
            } else {
                me.props.enqueueSnackbar(me.props.t('Invalid recover token'), { autoHideDuration: 5000, variant: 'error' });
            }

            console.error("[invalid recover] history go to home")
            
            setTimeout(() => {
                window.location.href = "/";    
            }, 3000);
            

        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    public render() {
        return (
            <Container>
                <img style={{   width: "80%", marginLeft: "10%", display: "block", marginTop: 20 }} src={Logo} />
                 <Typography style={{ textAlign: "center", marginTop: 30 }} component="h5" variant="h5">
                    {this.props.t('We are verifying your recover link')}
                   </Typography>
                <CircularProgress style={{ display: "block", margin: "auto", marginTop: 40 }} color="secondary" />
            </Container>
        )
    }
}

export default withRouter(withSnackbar(withTranslation()(RecoverLinkScreen)))