//import firebase from "firebase";
import { TransitionDone } from "./scenario";
import { User } from "./user";

export const EVENT_TRANSITION_DONE = "TRANSITION_DONE"

export class EventSender{

    public static transitionDone(transitionInfo:TransitionDone, targetUser:User){

/*         firebase.analytics().logEvent(EVENT_TRANSITION_DONE,
            {from: transitionInfo.transition.from,
             to:transitionInfo.transition.to,
             serviceId: targetUser.serviceId,
             clientId: targetUser.clientId });
 */
    }

}



