import { CHANGE_LOCALE } from "../../actions/user/i18n.action"
import { DISPLAY_ONBOARD_MOREINFOS } from "../../actions/user/onboarding.action"
import { ONBOARD_SET_BIRTHDAY, ONBOARD_SET_FIRST_NAME, ONBOARD_SET_GENRE, ONBOARD_SET_LAST_NAME, ONBARD_SET_PHONE, SET_CURRENT_USER } from "../../actions/user/user.action"
import { DefaultLocale } from "../../i18n"
import { GenreEnum } from "../../model/GenreEnum"

const initialState = {
    user: {  // used for register steps
        genre: GenreEnum.MALE,
        locale: DefaultLocale
    },
    currentUser: undefined, // fetched from backend after auth
    displayMoreInfoStepper:false,   
}


export default (state = initialState, action: any) => {
    switch (action.type) {

        case CHANGE_LOCALE:
            return {
                ...state,
                user: {
                    ...state.user,
                    locale: action.locale
                }
            }
        case DISPLAY_ONBOARD_MOREINFOS:
            
            
            console.log("SET STEPPER MORE INFO", action.display)
            return  {
                ...state,
                displayMoreInfoStepper: action.display
            }

        case SET_CURRENT_USER:
            let newState = {
                ...state,
                currentUser: action.data
            }
            
            console.log("SET_CURRENT_USER returned state", newState)
            return  newState

        case ONBOARD_SET_GENRE:
            return {
                ...state,
                user: {
                    ...state.user,
                    genre: action.genre
                }
            }
        case ONBARD_SET_PHONE:
            return {
                ...state,
                user: {
                    ...state.user,
                    phone: action.data
                }
            }
        case ONBOARD_SET_LAST_NAME:
            return {
                ...state,
                user: {
                    ...state.user,
                    lastName: action.data
                }
            }
        case ONBOARD_SET_FIRST_NAME:
            return {
                ...state,
                user: {
                    ...state.user,
                    firstName: action.data
                }
            }

        case ONBOARD_SET_BIRTHDAY:
            return {
                ...state,
                user: {
                    ...state.user,
                    birthday: action.data
                }
            }


        default:
            return state
    }
}