import { AppBar, Button, Container, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { connect } from 'react-redux';
import { Relation } from '../../model/relation';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PhoneInput from 'react-phone-input-2';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import examples from 'libphonenumber-js/examples.mobile.json'
import { getExampleNumber } from 'libphonenumber-js'

export interface AddRelationProps {
    onContactsAdded: (newRelation: Relation) => void;

    t: any;
    i18n: any;
}


export interface AddRelationState {
    name: string,
    number: string
}

class AddRelation extends React.Component<AddRelationProps, any> {

    constructor(props: AddRelationProps) {
        super(props)

        this.state = {
            manualWidget: [],
            name: undefined,
            number: undefined,
            country: undefined
        }
    }


    componentDidMount() {
        console.log("did mount add relation")
        //this.addRelation()
    }

    onPhoneRelationChange = (value, country) => {
        console.log("country", country)
        this.setState({ number: value, country: country ? country.countryCode : undefined })
    }

    onFirstNameRelationChange = (value) => {

        this.setState({ name: value })
    }

    addRelation = () => {

        let currentRelation = new Relation();
        currentRelation.name = this.state.name;
        currentRelation.phone = this.state.number;

        // TODO verify number

        this.props.onContactsAdded(currentRelation)
    }

    render() {
        let exempleForCountry:string = undefined;

        if(this.state.country){
            let phoneNumber = getExampleNumber(this.state.country.toUpperCase(), examples)
            exempleForCountry =   phoneNumber.formatInternational() 
        }

        return (





            <Container style={{ marginTop: 20, position: "relative" }}>



                <TextField
                    onChange={(event) => this.onFirstNameRelationChange(event.target.value)}
                    variant="outlined" margin="normal" required fullWidth id="relation_first_name" label={this.props.t('First name of your relation')} 
                    name="relation_first_name" autoFocus />

                <div style={{ width: "100%" }}>
                    <PhoneInput
                        fullWidth
                        country='fr'
                        enableAreaCodeStretch={true}
                        specialLabel={this.props.t('Phone number')}
                        placeholder={this.props.t('Please select a country')}
                        inputProps={{ margin: "normal", required: true, width: 200 }}
                        enableSearch={true}
                        value={this.state.phone}
                        countryCodeEditable={false}
                        onChange={(phone, country) => this.onPhoneRelationChange(phone, country)}
                    />

                </div>

                {this.state.country &&
                      <Container style={{marginTop: 5}}> {this.props.t('number exemple') + " " + exempleForCountry} </Container>
                }



                { this.state.number != undefined && this.state.name != undefined &&
                    <Button style={{ display: "block", margin: "auto", marginTop: 40 }} onClick={this.addRelation} variant="contained" color="primary">
                        {this.props.t('Add this relation')}
                     </Button>
                }


            </Container>


        );
    }
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(AddRelation)));
