import * as Sentry from "@sentry/react";

export default function initSentry() {

    console.info("init sentry")
    Sentry.init({
        dsn: "https://5be76361cd27cd501926e1a7abc2ffb1@o4506557459202048.ingest.sentry.io/4506557460709376",
        environment: process.env.NODE_ENV,
        integrations: [
                    ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
    });
}
