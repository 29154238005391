import React, { useEffect, useRef, useState } from "react"
import Logo from '../../assets/logo-medium.png';
import { firestoreDb } from "../../firebase";
import { User } from "../../model/user";
import { JsonUtils } from "../../Utils";
import firebaseLib from '../../firebase';
import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonBase, Card, CardActions, CardContent, CircularProgress, Container, Typography, capitalize, useTheme } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import { State, Transition, TransitionOperated } from "../../model/scenario";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'


import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import StarIcon from '@material-ui/icons/Star';
import { useHistory } from "react-router-dom";

interface LocationLocatePatientProps {

}

export const LocationLocatePatient = (props: LocationLocatePatientProps) => {

    let relationToken: string | null = null;
    const [patient, setPatient] = useState<User | undefined>(undefined)
    const [carouselIndex, setCarouselIndex] = useState<number>(0)
    const [selectedState, setSelectedState] = useState<string | undefined>(undefined)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    let carouselRef = useRef(null)
    const { t } = useTranslation()
    const theme = useTheme();
    const history = useHistory()


    const loadPatientLocation = async (relationToken: string) => {
        var relationLocatePatientCallable = firebaseLib.functions().httpsCallable('rmesRelation-locatePatient');

        return relationLocatePatientCallable({
            relationToken: relationToken
        }).then((result) => {
            let jsonConvert = JsonUtils.getJsonConvert();
            let patient: User = jsonConvert.deserialize(result.data.user, User) as User;
            setPatient(patient)
            console.log("patient", patient)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (patient) {

            let yop = t('DESCRIPTION_' + patient?.currentState, { returnObjects: true })
            console.log("yop", yop)

            let indexCarousel = findCarouselIndex(patient.currentState)
            setCarouselIndex(indexCarousel)
            setSelectedState(patient.currentState)
        }

    }, [patient])

    useEffect(() => {
        if (selectedState) {
            let indexCarousel = findCarouselIndex(selectedState)
            setCarouselIndex(indexCarousel)
        }

    }, [selectedState])


    const findCarouselIndex = (state: string): number => {
        let index = patient?.scenario.states.findIndex((st: State) => { return st.name == state })
        console.log("INDEX", index)
        return index || 0;
    }

    useEffect(() => {

        const {
            host, hostname, href, origin, pathname, port, protocol, search
        } = window.location

        const urlParams = new URLSearchParams(search);

        relationToken = urlParams.get('relationToken')
        console.log("access token", relationToken)
        if (relationToken) {
            loadPatientLocation(relationToken)
        }

    }, [])

    let descriptions:string[] = []
    
    if(selectedState){
        descriptions = t('DESCRIPTION_' + selectedState, { returnObjects: true }) as string[]
    }

    return (
        
        <Container>
            <img style={{ width: "80%", marginLeft: "10%", display: "block", marginTop: 20 }} src={Logo} />

            {isLoading == true &&
                <div style={{ textAlign: "center", marginTop: 20, marginBottom: 40 }}>
                    <Typography style={{ marginTop: 10, marginBottom: 30 }}>chargement des informations de votre proche</Typography>
                    <CircularProgress />

                </div>
            }
            <div>

                {patient != undefined &&
                    <div>


                        {/* main location */}
                        <div style={{ margin: "auto", marginTop: 20, marginBottom: 20, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography><strong style={{ color: theme.palette.secondary.main }}> {patient.getName()}</strong> est actuellement</Typography>
                            <Typography style={{ marginTop: 10, color: theme.palette.secondary.main }} variant="h6" > <strong>  {capitalize(t("RELATION_" + patient.currentState))}</strong> </Typography>
                            <Typography style={{ marginTop: 10 }}>à {patient.clientName}</Typography>
                        </div>

                        {/* carousel */}
                        <Carousel ref={carouselRef} selectedItem={carouselIndex} showArrows={true} showIndicators={false} showStatus={false} showThumbs={false}>
                            {
                                patient.scenario.states.map((state: State, index: number) => {
                                    let url = `/states/${state.name.toLowerCase()}_${patient.genre.toString()}.png`;
                                    if (state.name == patient.currentState) {
                                        return (<img key={index} src={url} />)
                                    } else {
                                        return (
                                            <img style={{ filter: "grayscale(1)", /* opacity: 0.1 */ }} key={index} src={url} />
                                        )
                                    }
                                })
                            }

                        </Carousel>


                        {/* helper Accordion */}
                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flex: 1 }} >
                                        {/* <LiveHelpIcon style={{fontSize: 15, marginRight: 5, color: "gray"}}/> */}
                                        <small>En savoir plus sur : </small>
                                        <span>{t("RELATION_" + selectedState)}</span> </Typography>


                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        {selectedState && descriptions.length > 0 && descriptions.map((desc: string) => {
                                            return (
                                                <Typography style={{ marginTop: 20, textAlign: "justify" }}>
                                                    {desc}
                                                </Typography>
                                            )
                                        })
                                        }
                                    </div>


                                </AccordionDetails>
                            </Accordion>
                        </div>





                        {/* timeline */}
                        <Timeline style={{ padding: 0, margin: 0, marginTop: 20, marginBottom: 40 }}>
                            {patient?.operatedTransitions && patient?.operatedTransitions.map((o: TransitionOperated, index: number) => {

                                let isCurrentState: boolean = o.to == patient.currentState && index == patient?.operatedTransitions.length - 1

                                return (
                                    <div>
                                        <ButtonBase


                                            style={{
                                                width: "100%", display: "block",

                                                borderStyle: isCurrentState == true ? "solid" : "none", borderWidth: 2, borderColor: isCurrentState ? "#bdbdbd" : "white", borderRadius: 10
                                            }}


                                            key={index} onClick={() => {
                                                setSelectedState(o.to)
                                            }}>
                                            <TimelineItem
                                                style={{
                                                    alignItems: isCurrentState == true ? "center" : "normal"
                                                }}
                                            >
                                                <TimelineOppositeContent style={{ width: "100%" }}>
                                                    <Typography
                                                        style={{
                                                            fontWeight: isCurrentState == true ? "bold" : "initial"
                                                        }}

                                                        color="textSecondary">
                                                        <span >{isCurrentState == true ? "👉 " : ""}</span> <small>{moment(o.date).format('DD/MM HH:mm')}</small></Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot style={{ background: isCurrentState ? theme.palette.secondary.main : theme.palette.primary.light }} />
                                                    {index != patient.operatedTransitions.length - 1 &&
                                                        <TimelineConnector />
                                                    }

                                                </TimelineSeparator>
                                                <TimelineContent >
                                                    <Typography
                                                        style={{

                                                            fontWeight: isCurrentState == true ? "bold" : "initial"
                                                        }}
                                                    >
                                                        {capitalize(t('RELATION_' + o.to))}</Typography>



                                                </TimelineContent>
                                            </TimelineItem>

                                            {/* [operation succes] display if last official state or forceFinishPath == true */}
                                            {((patient.scenario.patient_can_end_state == o.from && o.to == "ROOM") || o.forceFinishPath == true) &&
                                                <div style={{ display: "flex", justifyContent: "center", marginTop: 20, marginBottom: 10 }}>
                                                    <Typography >Opération terminée ✅</Typography>                                                    


                                                </div>
                                                
                                            }


                                            


                                        </ButtonBase>



                                    </div>

                                )
                            })
                            }
                        </Timeline>



                        {/* rate rmes */}
                        <div>

                            <Card style={{ marginBottom: 100 }}>
                                <CardContent>
                                    <Typography style={{ padding: 10 }} color="textSecondary" gutterBottom>
                                        Satisfait du service RMES ? Nous avons besoin de vous pour nous aider à développer la solution 🙏
                                    </Typography>
                                    <CardActions>
                                        <Button
                                            onClick={() => {
                                                window.location.replace("https://g.page/r/CQqel0qAgCUQEAI/review")
                                            }}
                                            endIcon={<StarIcon style={{ color: "yellow" }}>send</StarIcon>}
                                            variant="contained" color="secondary">
                                            Notez le service RMES
                                        </Button>
                                    </CardActions>
                                </CardContent>
                            </Card>


                        </div>





                    </div>
                }
            </div>
        </Container>
    )
};

export default LocationLocatePatient;
