import { Checkbox, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import { IQuestionResponse, ISurveyQuestion } from "../../model/Survey/SurveyReferential";
import { capitalize, cloneDeep } from 'lodash';

interface CheckboxSurveyProps {
    question: ISurveyQuestion | undefined;
    containerStyle: any;
    onValueChange: () => void;
    readonly:boolean;
    displayColor:boolean;
}


const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    red:{
        backgroundColor: "#ff6961"
    },
    green:{
        backgroundColor: "#77DD77"
    },
    orange:{
        backgroundColor: "#f9a976"
    },


    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
    },
    checkedIcon: {

      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
  });

/* props data are modified in this component */
export const CheckboxSurveyElement = (props: CheckboxSurveyProps) => {

    const [question, setQuestion] = useState<ISurveyQuestion | undefined>(undefined)
    const [value, setValue] = React.useState<string | undefined>(undefined);
    const [readOnly, setReadonly] = React.useState<boolean>(false);

    let classes = useStyles()

    useEffect(() => {
      setReadonly(props.readonly)        
    }, [props.readonly])
    
    useEffect(() => {
        if (props.question) {
            setQuestion(props.question)
        }
    }, [])



    const handleChange = (target: IQuestionResponse, checked: boolean) => {

        console.log("event checked response:", target.response, "checked", checked)

        props.question?.responses.forEach((r: IQuestionResponse) => {
            if (r.id == target.id) {
                r.selected = !r.selected
                console.log("checked", r.selected)
            }else{
                console.log("not found")
            } 
        })

        setQuestion( cloneDeep(props.question) )

        props.onValueChange()

     
    };


    return (
        <Container style={props.containerStyle}>

            {question != undefined &&

                <FormControl style={{marginTop: 20}}>

                    <FormLabel style={{marginBottom: 10}} id="demo-radio-buttons-group-label">{capitalize(question.question)}</FormLabel>



                    {
                        question.responses.map((r: IQuestionResponse, index:number) => {
                            
                            let additionalProps = {}
                            
                            if(props?.displayColor == true){
                                  
                                additionalProps = {
                                    checkedIcon: <span className={`${classes.icon} ${classes.checkedIcon} ${classes[r.color]}`}/>
                                }
                            }
                            
                            return (

                                <FormControlLabel
                                key={"checkbox-"  + index}
                                    control={
                                        <Checkbox
                                        inputProps={{ 'style': { backgroundColor: "red"} }}
                                            disabled={readOnly}
                                            {...additionalProps}                                            
                                            checked={r.selected}
                                            onChange={(e) => { 
                                                console.log("checked", e.target.checked)
                                                handleChange(r, e.target.checked) } }
                                            value={r.id} />


                                    }
                                    label={r.response}
                                />


                            )
                        })
                    }


                </FormControl>
            }

        </Container>
    )
};

export default CheckboxSurveyElement;
