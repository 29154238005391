import { Button, Container, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react"
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { firestoreDb } from "../firebase";
import { IColorsCounters, IUserSurvey, countSurveyColors, patientMustBeContacted } from "../model/Survey/UserSurvey";
import Logo from '../assets/logo-medium.png';
import { IQuestionResponse, ISurveyQuestion, QuestionType } from "../model/Survey/SurveyReferential";
import RadioSurveyElement from "../components/Survey/RadioSurveyElement";
import { capitalize } from 'lodash';
import CheckboxSurveyElement from "../components/Survey/CheckboxSurveyElement";
import { firestore } from "firebase-functions/v1";
import { DocumentData, DocumentReference, QueryDocumentSnapshot } from "@google-cloud/firestore";

interface SurveyScreenProps {
    history: any;
}

export const SurveyScreen = (props: SurveyScreenProps) => {

    let accessToken: string | null = null;

    const [userSurvey, setUserSurvey] = useState<IUserSurvey | undefined>(undefined);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(true);
    const [docId, setDocId] = useState<string|undefined>(undefined);
    const [readonly, setReadonly] = useState<boolean>(false);




    const onSaveSurvey = async () => {
        console.log("go save survey", docId)

        if (docId) {
            console.log("save survey to firestore")

                        
            let counters:IColorsCounters = countSurveyColors(userSurvey)

            console.log("survey counters", counters)


            await   firestoreDb.collection("survey").doc(docId).update({
                elements: userSurvey?.elements,
                answered: true,
                answeredAt: new Date(),
                finalColor: counters.finalColor
            })

            let mustBeContacted:boolean = (counters.red > 0 || counters.orange > 0)

            props.enqueueSnackbar("Merci le processus RMES est maintenant terminé", { variant: 'success', autoHideDuration: 2000 });
            
            if(mustBeContacted == false){
                props.enqueueSnackbar("Au regard de vos réponses, vous n'avez pas besoin d'être contacté", { variant: 'success', autoHideDuration: 10000 });
            }else{
                props.enqueueSnackbar("Au regard de vos réponses, Vous allez être contacté par le personnel soignant 👩‍⚕️👨‍⚕️", { variant: 'warning', autoHideDuration: 10000 });
            }

            setTimeout(() => {
                console.log("[token valid] history go to home")
                window.location.href = "/";    
            }, 12000); // wait for toaster
            
        }
    }

    const loadSurvey = async () => {

        console.log("load survey")

        const surveyQuerySnapshot = await firestoreDb.collection("survey").where('accessToken', '==', accessToken).limit(1).get()

        if (!surveyQuerySnapshot.empty) {

            console.log("survey seems to be found")

           // surveyFirebaseRef = surveyQuerySnapshot.docs[0]

           setDocId(surveyQuerySnapshot.docs[0].id)
           console.log("rod", docId)
           let survey: IUserSurvey = surveyQuerySnapshot.docs[0].data() as IUserSurvey;

            if (survey) {
                console.log("survey data", survey)
                survey.birthday = new Date(survey.birthday.seconds * 1000) // convert firebase timestamp to Date

                if(survey.answered == true){
                    setReadonly(true)
                }

                /* let counters = countSurveyColors(survey)
                console.log("survey counters", counters)
                 */

                setUserSurvey(survey)
            } else {
                throw "survey not loaded from access token"
            }


        } else {
            throw 'error verifying service code'
        }
    }



    useEffect(() => {

        const {
            host, hostname, href, origin, pathname, port, protocol, search
        } = window.location

        const urlParams = new URLSearchParams(search);

        accessToken = urlParams.get('accessToken')
        console.log("access token", accessToken)

        loadSurvey()

    }, [])


    let elementStyle = { marginTop: 40, backgroundColor: "#e3f8f8", borderRadius: 12.5, paddingTop: 0, paddingBottom: 20 }

    const onValueChangeInChild = () => {

        let canSendSurvey: boolean = false;

        userSurvey?.elements.every((e: ISurveyQuestion) => {

            let oneSelected: IQuestionResponse | undefined = e.responses.find((r: IQuestionResponse) => {
                return r.selected == true
            })

            if (oneSelected == undefined) {
                canSendSurvey = false;
                return false; // means break every() loop
            } else {
                canSendSurvey = true
                return true // means continue every() loop
            }
        })

        if (canSendSurvey == true) {
            setSaveBtnDisabled(false)
        }
    }

    return (
        <Container>
            <img style={{ width: "80%", marginLeft: "10%", display: "block", marginTop: 20 }} src={Logo} />


            {userSurvey != undefined &&
                <Container>

                    <Typography style={{ fontSize: 20, marginTop: 60 }} >Bonjour {userSurvey.firstName} {userSurvey.lastName} </Typography>
                    <Typography style={{ marginTop: 20, marginBottom: 50 }} >Pour s'assurer que votre opération s'est bien passée, veuillez s'il vous plait répondre à ce questionnaire</Typography>

                    {userSurvey.elements.map((e: ISurveyQuestion) => {

                        if (e.type == QuestionType.RADIO) {
                            return (<RadioSurveyElement
                                readonly={readonly}
                                onValueChange={onValueChangeInChild}
                                containerStyle={elementStyle} question={e}></RadioSurveyElement>)
                        }

                        if (e.type == QuestionType.CHECKBOX) {

                            return (<CheckboxSurveyElement
                                readonly={readonly}
                                onValueChange={onValueChangeInChild}
                                containerStyle={elementStyle} question={e} ></CheckboxSurveyElement>)
                        }
                    })

                    }

                    {saveBtnDisabled == true &&
                        <Typography style={{ marginTop: 20, color: "red" }}>Vous devez répondre à toutes les questions pour sauvegarder</Typography>
                    }

                    <Button
                        onClick={() => { onSaveSurvey()  }}
                        disabled={saveBtnDisabled}
                        style={{ marginTop: 60, marginBottom: 60, width: "60%", marginLeft: "20%" }} variant="contained" color="primary"  >Répondre</Button>

                </Container>
            }




        </Container>
    )
};


export default withRouter(withSnackbar(withTranslation()(SurveyScreen)))