import { AppBar, Badge, Box, Button, Container, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ContactSupportOutlined } from '@material-ui/icons';
import * as React from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { setCurrentUser } from '../../actions/user/user.action';
import { User } from '../../model/user';
import OperationDatePage from './OperationDate.page';
import RelationsPage from './Relations.page';
import RoomPage from './Room.page';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FlagSelector from '../../components/localization/FlagSelector';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import firebase from 'firebase';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
export interface PatientConfigurationProps {
  history: any;
  t: any;
  i18n: any;
  currentUser: User;
}


export interface PatientConfigurationState {
}

class PatientConfiguration extends React.Component<PatientConfigurationProps, PatientConfigurationState> {

  displayPatientState = () => {
    this.props.history.push("/patient/state")
  }

  goOnDetailConfiguration = (route: any) => {
    console.log("go on route", route)
    this.props.history.push(route)
  }

  componentDidUpdate() {
    console.log("patient configuration")
  }

  onDisconnect = () => {
    var answer = window.confirm(this.props.t('Confirm logout'));
    if (answer) {
      firebase.auth().signOut().then(() => {
        this.props.history.push("/")
      })
    } 
  }

  render() {
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <IconButton onClick={this.displayPatientState} edge="start" color="inherit" aria-label="menu">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" >
              {this.props.currentUser.toString()}
              {/* {this.props.t('Update your informations')} */}
            </Typography>


            <Box style={{ justifyContent: "flex-end", display: "flex", flex: 1 }} sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="medium"
                aria-label="show more"
                aria-controls={'disconnect'}
                aria-haspopup="true"
                onClick={this.onDisconnect}
                color="inherit"
              >                
                <MeetingRoomIcon />
              </IconButton>
            </Box>

          </Toolbar>
        </AppBar>

        <img style={{ width: "80%", textAlign: "center", margin: "auto", display: "block" }} src={`/img/configuration_${this.props.currentUser.genre.toString()}.png`}></img>

        <div style={{ height: "100vh" }}>
          <div style={{ display: "flex", height: "50%", flex: 1, flexDirection: "column", justifyContent: "space-around", alignItems: "center", alignContent: "center" }}>



            <FlagSelector></FlagSelector>
{/*             <Button onClick={() => { this.goOnDetailConfiguration("/patient/configuration/room") }} variant="contained" size="large" color="primary" >
              {this.props.t('Your number room')}
            </Button>
 */}
            <Badge invisible={this.props.currentUser?.relations?.length != 0} badgeContent={'!'} color="error">
            <Button onClick={() => { this.goOnDetailConfiguration("/patient/configuration/relations") }} variant="contained" size="large" color="primary" >
              {this.props.t('Your relations')} <b style={{marginLeft: 10}}>(<span style={{marginLeft: 2, marginRight: 2}}>
                {this.props.currentUser.relations == undefined ? 0 : this.props.currentUser.relations?.length}</span>)</b>
            </Button>
            </Badge>

            <Button onClick={() => { this.goOnDetailConfiguration("/patient/configuration/operationDate") }} variant="contained" size="large" color="primary" >
              {this.props.t('Your operation date')}

            </Button>
          </div>

        </div>
      </div>
    );
  }
}




const mapStateToProps = state => {
  return {
    currentUser: state.userReducer.currentUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentUser: (currentUser: User) => dispatch(setCurrentUser(currentUser))
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(PatientConfiguration)));
