
import { Button, withStyles } from "@material-ui/core";
export const SoftButton = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: "lightgray",
      color: "gray",
      borderColor: 'lightgray',
    },
  })(Button);