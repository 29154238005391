import * as React from 'react';


import {
    BrowserRouter,
    withRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import CodeRegister from './onboard/CodeRegister';
import Genre from './onboard/Genre';
import Info from './onboard/Info';
import Welcome from './onboard/Welcome';


export interface OnBoardPatientProps {
    history: any;
}

class OnBoardPatient extends React.Component<OnBoardPatientProps, any> {
    constructor(props: OnBoardPatientProps) {
        super(props);
        console.log("onbard")
          this.props.history.push('/onboarding/welcome')

    }

    public render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/onboarding/welcome"><Welcome /></Route>
                    <Route path="/onboarding/genre"><Genre /></Route>
                    <Route path="/onboarding/infos"><Info/></Route>
                    <Route path="/onboarding/code"><CodeRegister/></Route>
                    <Route path="/onboarding/install"><CodeRegister/></Route>
                </Switch>
            </BrowserRouter>

        )
    }
}

export default withRouter(OnBoardPatient)