import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import './i18n';
import { Provider } from 'react-redux'
import configureStore from './store';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


import moment from 'moment'

import 'moment/locale/fr'  // without this line it didn't work
import { DefaultLocale } from './i18n';
import { version } from './service-worker';
moment.locale(DefaultLocale)


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#678ebf',
      main: '#36618f',
      dark: '#003761',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6cdddb',
      main: '#31aba9',
      dark: '#007b7a',
      contrastText: '#ffffff',
    },
  },
});

const snackStyle = {
  success: { backgroundColor: '#31aba9!important' },
  error: { backgroundColor: 'blue' },
  warning: { backgroundColor: 'green' },
  info: { backgroundColor: 'yellow' },
  contentRoot: {
    backgroundColor: 'aqua',
 },
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <SnackbarProvider maxSnack={3} classes={{    variantSuccess: "red"  }}>
    <React.StrictMode>

    <Provider store={configureStore()}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
      </MuiPickersUtilsProvider>
      </Provider>
   
    </React.StrictMode>
  </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA


console.log("service worker unregister")
serviceWorkerRegistration.unregister()

/* serviceWorkerRegistration.register({

  // to force update increment version there => src/service-worker.ts
  onUpdate: (registration:any) => {
    console.info("new service worker : reload with new one", registration)
    
    alert("Un nouvelle version est disponible")

    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
  onSuccess: (registration:any) => {
    console.log("service worker registration succes", registration)
  },

}, "1.0.24");
 */
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
