import React from 'react';

import firebaseLib, { firestoreDb } from './firebase';
import { withRouter } from 'react-router-dom';
import './App.css';
import OnBoardPatient from './pages/OnboardPatient';
//  @ts-ignore
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import Logo from './assets/logo-medium.png'

import moment from 'moment'
import {
  BrowserRouter,
  Switch,
  Redirect,
  Route,
  Link
} from "react-router-dom";
import OnboardPatient from './pages/OnboardPatient';
import Patient from './pages/Patient';

import configureStore from './store';
import Snackbar from '@material-ui/core/Snackbar';
import { CircularProgress } from '@material-ui/core';
import { logger } from 'workbox-core/_private';
import { setCurrentUser } from './actions/user/user.action';
import { User } from './model/user';
import { JsonUtils } from './Utils';
import firebase from 'firebase';
import RecoverLinkScreen from './pages/RecoverLinkScreen';
import SurveyScreen from './pages/SurveyScreen';
import LocationLocatePatient from './pages/relations/RelationLocatePatient';
import { version } from './service-worker';
import initSentry from './Sentry';

export enum currentUserState {
  LOADING,
  SIGNED,
  UNSIGNED,
  RECOVER,
  SURVEY,
  RELATION_LOCATE_PATIENT
}



interface AppState {
  currentUserState: currentUserState
}

interface AppProps {
  setCurrentUser: (currentUser: User) => void
  currentLocale: string;
  t: any;
  i18n: any
}

export var deferredPrompt: any;


class App extends React.Component<AppProps, AppState> {

  private unregisterRealtimeListener: any;

  constructor(props: AppProps) {
    super(props)

    initSentry()

    


    this.state = {
      currentUserState: currentUserState.LOADING
    }

    


    console.log("register defered prompt")
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      //showInstallPromotion();
      console.log("defered prompt catched", deferredPrompt)
      //deferredPrompt.prompt();
    });

  }

  componentWillUnmount() {
    if (this.unregisterRealtimeListener) {
      this.unregisterRealtimeListener();
    }
  }

  componentDidMount() {
    
    const {
      host, hostname, href, origin, pathname, port, protocol, search
    } = window.location


    // if comes from recover link in sms 
    // we directly logout then redirect and DO NOT register firebase listeners for login/logout
    // the "return" here is important
    if (pathname.includes('/recover')) {
      return firebase.auth().signOut().then(() => {
        this.setState({ currentUserState: currentUserState.RECOVER })
        return;
      })
    }

    // if comes from survey link in sms 
    // we keep the possibility to be logged after survey is answered and user is redirected to /
    // the "return" here is important
    if (pathname.includes('/survey')) {
      this.setState({ currentUserState: currentUserState.SURVEY })
      return
    }

    if (pathname.includes('/relationLocatePatient')) {
      this.setState({ currentUserState: currentUserState.RELATION_LOCATE_PATIENT })
      return
    }


    // if not recover link go bellow ...


    // prepare the listener for auth ok/ko 
    // resolve directly
    let me = this;

    firebaseLib.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("user is sigin")

        let user = firebaseLib.auth().currentUser
        let userUid: string = user?.uid as string;

        firebase.analytics().setUserId(userUid)

        console.log("current userUid", userUid)
        //.onSnapshot((patientsQuerySnapshot) => {
        //firestoreDb.collection('user').doc(userUid).get().then(function (doc) {

        this.unregisterRealtimeListener = firestoreDb.collection('user').doc(userUid).onSnapshot((doc) => {

          if (doc.exists) {
            console.log("Document data:", doc.data());
            console.log("Document id:", doc.id);

            let userObject: any = doc.data();
            userObject['id'] = doc.id // very important

            let jsonConvert = JsonUtils.getJsonConvert();
            let currentUser: User = jsonConvert.deserialize(userObject, User) as User;

            //let currentUser:User = Object.assign(new User(), userObject);


            me.props.setCurrentUser(currentUser); // dispatch
            me.setState({ currentUserState: currentUserState.SIGNED })
          } else {
            // doc.data() will be undefined in this case
            console.log("no any user data found except auth one, redirect to bootstrap app");
            firebaseLib.auth().signOut().then(function () {
              document.location.href = "/";
              console.log('Signed Out');
            }, function (error) {
              console.error('Sign Out Error', error);
            });

          }
        }, function (error) {
          console.log("Error getting document:", error);
        })







      } else {
        me.setState({ currentUserState: currentUserState.UNSIGNED })
        console.log("user is NOT sign")
      }
    });

  }


  componentDidUpdate(prevProps) {
    if (prevProps.currentLocale !== this.props.currentLocale) {
      console.info("globally change language/locale")
      this.props.i18n.changeLanguage(this.props.currentLocale);
      moment.locale(this.props.currentLocale)
    }
  }


  render() {

    const {
      host, hostname, href, origin, pathname, port, protocol, search
    } = window.location

    return (

      <BrowserRouter >

        {this.state.currentUserState == currentUserState.LOADING &&
          <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <img style={{ width: "80%", display: "block", margin: "auto", marginTop: 20 }} src={Logo} />
            <CircularProgress style={{ display: "block", margin: "auto", marginTop: 40 }} color="secondary" />
          </div>
        }

        <Switch>
          {this.state.currentUserState == currentUserState.SIGNED &&
            <Route exact > <Redirect to="/patient" /> <Patient /> </Route>
          }

          {this.state.currentUserState == currentUserState.UNSIGNED &&
            <Route  >   <Redirect to="/onboarding" />  <OnboardPatient /></Route>
          }

          {this.state.currentUserState == currentUserState.SURVEY &&
            <Route>   <Redirect to={{
              pathname: "/survey",
              search: search,
            }} />  <SurveyScreen />
            </Route>
          }

          {this.state.currentUserState == currentUserState.RELATION_LOCATE_PATIENT &&
            <Route>   <Redirect to={{
              pathname: "/relationLocatePatient",
              search: search,
            }} />  <LocationLocatePatient />
            </Route>
          }


          {this.state.currentUserState == currentUserState.RECOVER &&
            <Route>   <Redirect to={{
              pathname: "/recover",
              search: search,
            }} />  <RecoverLinkScreen />
            </Route>
          }


        </Switch>
      </BrowserRouter>

    )

  }


}
const mapDispatchToProps = dispatch => ({
  setCurrentUser: (currentUser: User) => dispatch(setCurrentUser(currentUser))
})

const mapStateToProps = state => ({
  currentLocale: state.i18nReducer.language.locale
})


export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withTranslation()(App)));




//export default App;
