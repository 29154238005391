import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Logo from '../../assets/logo-medium.png'
import { User } from "../../model/user";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import HomeIcon from '@material-ui/icons/Home';

export interface PatientProps {
  t: any;
  currentUser: User;
}

class ExitPage extends React.Component<PatientProps> {
  constructor(props: PatientProps) {
    super(props);
    console.info("we do not really unlog the user - just display a 'bye bye' page")
  }

  render() {
    return (
      <div>
       

      <div style={{marginTop: 25, flex:1, padding:5,  flexDirection: "row", justifyContent: "center"}}>

         <img style={{  width: "80%", marginLeft: "10%", display: "block", marginTop: 10 }} src={Logo} />


        <Typography style={{textAlign: "center", marginTop: 40, color: "#636971" }} variant="h3" component="h2">
          {this.props.t('Thank you')}
        </Typography>

        <Typography style={{textAlign: "center", marginTop: 40, color: "#636971"}}  variant="h5" component="h3">
          {this.props.t('Your visit is now terminated', {clientName: this.props.currentUser.clientName})}
        </Typography>


        <Typography style={{textAlign: "center", marginTop: 40, color: "#636971"}}  variant="h5" component="h3">
          {this.props.t('You can close the application')}
        </Typography>

      <div style={{flex:1, flexDirection: "row", justifyContent: "center", marginTop: 20, display: "flex"}}>
            <DirectionsWalkIcon style={{fontSize: 80, textAlign: "center",   color: "#636971"}}></DirectionsWalkIcon>
            <HomeIcon style={{fontSize: 80, textAlign: "center", color: "#636971"}}></HomeIcon>
        </div>

        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    currentUser: state.userReducer.currentUser
  };
}


export default connect(mapStateToProps, null)(withTranslation()(ExitPage));
