import { User } from "../../model/user"
import { GenreEnum } from "../../pages/onboard/Genre"


/* CURRENT USER (authenticated) */
export const SET_CURRENT_USER = "SET_CURRENT_USER"

export function setCurrentUser(currentUser:User){
    return {
        type: SET_CURRENT_USER,
        data: currentUser
    }
}


/* ONBOARDING (before register) */
export const ONBOARD_SET_GENRE = "SET_GENRE"
export const ONBOARD_SET_BIRTHDAY = "SET_BIRTHDAY"
export const ONBOARD_SET_FIRST_NAME = "SET_FIRST_NAME"
export const ONBOARD_SET_LAST_NAME = "SET_LAST_NAME"
export const ONBARD_SET_PHONE = "SET_PHONE"


export function setGenre(genre: GenreEnum) {
    return {
        type: ONBOARD_SET_GENRE,
        genre: genre
    }
}

export function setBirthday(birth: Date) {
    return {
        type: ONBOARD_SET_BIRTHDAY,
        data: birth
    }
}

export function setFirstName(firstName: string) {
    return {
        type: ONBOARD_SET_FIRST_NAME,
        data: firstName
    }
}

export function setLastName(lastName: string) {
    return {
        type: ONBOARD_SET_LAST_NAME,
        data: lastName
    }
}

export function setPhone(phone: string) {
    return {
        type: ONBARD_SET_PHONE,
        data: phone
    }
}