import { Avatar, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, LinearProgress, makeStyles, TextField, Typography, withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import * as React from 'react';
import { ReactReduxContext, connect } from 'react-redux';
import { User } from '../../model/user';
import moment from 'moment'
import {
    BrowserRouter,
    withRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { DatePicker } from "@material-ui/pickers"
import 'react-phone-number-input/style.css'

import { setBirthday, setFirstName, setGenre, setLastName, setPhone } from '../../actions/user/user.action';
import { withTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { GenreEnum } from '../../model/GenreEnum';
import examples from 'libphonenumber-js/examples.mobile.json'

import { AsYouType, parsePhoneNumberWithError, parsePhoneNumberFromString, ParseError } from 'libphonenumber-js/max'
import {getExampleNumber, PhoneNumberUtil}  from 'libphonenumber-js'

export interface InfoProps {
    router: any;
    classes: any;
    user: User;
    currentUser: User;
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
    setBirthday: (b: Date) => void;
    setPhone: (p: string) => void;
    enqueueSnackbar: (mesg: string, options: any) => void;
    t: any;
    i18n: any;
}

export interface InfoState {
    currentUser: User;
    phone: string;
    mobilePhoneHelper: boolean
}

class Info extends React.Component<InfoProps, any> {


    public refs: any;
    public input: any;

    constructor(props: InfoProps) {
        super(props);
        this.state = {
            mobilePhoneHelper: false,
            country: "fr",
            currentUser: {
                birthday: undefined
            }
        }


    }

    componentDidMount() {
        console.log("props user: ", this.props.user)
        let newUser = Object.assign(new User(), this.props.user);
        console.log("new user", newUser)
        this.setState({ currentUser: newUser }, () => {
            console.log("state user", this.state.currentUser)
        })

    }

    setPhoneValue = (value: string) => {
        //console.log("phone value", value)
        this.state.currentUser.phone = value
    }

    onFirstNameChange = (event) => {
        console.log("input", event)
        this.state.currentUser.firstName = event.target.value;
    }

    onLastNameChange = (event) => {
        this.state.currentUser.lastName = event.target.value;
    }

    onBirthdayDate = (value) => {
        console.log("birthday", value)
        console.log("birthday to date()", value.toDate())

        //this.setState({currentUser: { ...this.state.currentUser, birthday: value.toDate()  }})
        this.state.currentUser.birthday = value.toDate();
        this.setState({ currentUser: this.state.currentUser })
    }

    // should generate only mobile phone exemples, but i have managed to with installed libs
    getPhoneNumberExemple = () => {
        let phoneNumber = getExampleNumber(this.state.country.toUpperCase(), examples)
        let exempleForCountry =   phoneNumber.formatInternational() 
        return exempleForCountry
    }

    continue = () => {

        let result = this.state.currentUser.isValidForRegister();

        try {
            const phoneNumber: PhoneNumber = parsePhoneNumberWithError("+" + this.state.currentUser.phone)
            console.log("[check] phone number", phoneNumber)

            let numberType = phoneNumber.getType();

            if (numberType != 'MOBILE') {
                this.props.enqueueSnackbar(`${this.props.t('Info missing')} : ${this.props.t('Your phone number')}`, {
                    anchorOrigin: {horizontal: 'center', vertical: 'top' },
                    variant: 'error', autoHideDuration: 2000 });
                    this.setState({mobilePhoneHelper: true})
                return
            }
        } catch (error) {
            console.error(error)
            this.props.enqueueSnackbar(`${this.props.t('Info missing')} : ${this.props.t('Your phone number')}`, {
                anchorOrigin: {horizontal: 'center', vertical: 'top' },
                variant: 'error', autoHideDuration: 2000 });
            this.setState({mobilePhoneHelper: true})
            return
        }


        if (result == true) {
            console.log("user ", this.state.currentUser)
            console.log("VALID FOR REGISTER")

            this.props.setPhone(this.state.currentUser.phone)
            this.props.setFirstName(this.state.currentUser.firstName)

            // remove space and accents for search purpose
            let trimedLastName = this.state.currentUser.lastName.trim()
            let removedAccent = trimedLastName.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            this.state.currentUser.lastName = removedAccent;

            this.props.setLastName(this.state.currentUser.lastName)
            this.props.setBirthday(this.state.currentUser.birthday)

            this.props.history.push('/onboarding/code')


        } else {
            this.props.enqueueSnackbar(`${this.props.t('Info missing')} : ${this.props.t(result)}`, { variant: 'error', autoHideDuration: 2000 });
        }



    }

    public render() {

        const { classes } = this.props;

        let userGenre: GenreEnum;

        if (this.props.currentUser) { // MUST first check currentUser
            userGenre = this.props.currentUser.genre;
        } else if (this.props.user) {
            userGenre = this.props.user.genre;
        } else {
            userGenre = GenreEnum.MALE;
        }


        return (

            <Container component="main" maxWidth="xs">

                <LinearProgress variant="determinate" value={66} />
                <CssBaseline />

                <Typography style={{ textAlign: "center", marginTop: 30 }} component="h5" variant="h5">{this.props.t('Your informations')}  </Typography>

                <img style={{ display: "block", borderRadius: 20, margin: "auto", width: "100%", marginTop: 30 }} src={`/img/configuration_${userGenre.toString()}.png`}></img>

                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-around" }}>






                    <TextField
                        ref={(input) => this.input = input}
                        onChange={this.onFirstNameChange}
                        value={this.state.currentUser.firstName}
                        variant="outlined" margin="normal" required fullWidth id="first_name" label={this.props.t('Your last name as referenced')} name="first_name" autoFocus />


                    <TextField onChange={this.onLastNameChange}
                    value={this.state.currentUser.lastName}
                        variant="outlined" margin="normal" required fullWidth id="ladt_name" label={this.props.t('Your first name as referenced')}
                        name="last_name" />

                    <DatePicker
                        style={{ width: "100%", marginTop: 16, marginBottom: 8 }}
                        disableFuture
                        inputVariant="outlined"
                        cancelLabel={this.props.t('Cancel')}
                        openTo="year"
                        labelFunc={(date, invalidLabel) => { return moment(date).format("Do MMMM YYYY") }}
                        label={this.props.t('Your birthday')}
                        views={["year", "month", "date"]}
                        value={this.state.currentUser.birthday}
                        onChange={(value) => this.onBirthdayDate(value)}
                    />




                    <PhoneInput
                        specialLabel={this.props.t('Your mobile phone number')}
                        onlyCountries={['fr', 'us']}
                        country={'fr'}
                        fullWidth
                        onFocus={() => {
                            this.setState({mobilePhoneHelper: false})
                        }}
                        countryCodeEditable={false}
                        style={{ marginTop: 16, marginBottom: 8 }}
                        placeholder={this.props.t('Your phone number')}
                        value={this.state.currentUser.phone}
                        onChange={(value, country) => {
                            
                            if(country){
                                this.setState({country: country.countryCode.toUpperCase()})
                            }
                             this.setPhoneValue(value)}
                         } />

                    <div style={{ fontStyle: "italic", marginTop: 1, textAlign: "right" }}>exemple  
                        <span style={{ marginLeft: 5, color: this.state.mobilePhoneHelper == true ? "red" : "black", fontWeight: 'bold' }}>
                            {this.getPhoneNumberExemple()}
                        </span>
                        {this.state.mobilePhoneHelper == true &&
                            <div style={{ color: "red" }}>

                                Utilisez la numéronation internationale

                            </div>
                        }
                    </div>




                    <Button onClick={this.continue} fullWidth variant="contained" style={{
                        
                        textAlign: "center", margin: "auto",  marginBottom: 8, display: "block",
                        marginTop: 30, }}
                        color="primary" className={classes.submit}
                    >
                        {this.props.t('Continue')}
                    </Button>


                </div>

            </Container>

        );
    }
}


const useStyles = {
    paper: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: 10,
        backgroundColor: "white",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 10,
    },
    submit: {
        margin: 10,
    },
}





const mapDispatchToProps = dispatch => ({
    setFirstName: (firstName: string) => dispatch(setFirstName(firstName)),
    setLastName: (lastName: string) => dispatch(setLastName(lastName)),
    setBirthday: (b: Date) => dispatch(setBirthday(b)),
    setPhone: (p: string) => dispatch(setPhone(p)),
})

const mapStateToProps = state => ({
    user: state.userReducer.user,
    currentUser: state.userReducer.currentUser,
})


export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(withStyles(useStyles)(withTranslation()(Info)))));
