import { Button } from '@material-ui/core';
import * as React from 'react';

import firebase from '../firebase'

import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { User } from '../model/user';
import RoomPage from './patient/Room.page';
import OperationDatePage from './patient/OperationDate.page'
import RelationsPage from './patient/Relations.page'
import PatientStatePage from './patient/PatientState.page'
import PatientConfiguration from './patient/PatientConfiguration.page'


import {
  BrowserRouter,
  withRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Install from './patient/Install';
import ExitPage from './patient/ExitPage';

enum userRegisteredInfoValidationAction {
  NEXT_STEP,
  PATIENT_STATE
}

export interface PatientProps {
  history: any; //router
  currentUser: User;
  displayStepper:boolean;
}

class Patient extends React.Component<PatientProps, any> {
  constructor(props: PatientProps) {
    super(props);
    console.log("PATIENT PROPS", this.props)
  }

  logout = () => {
    firebase.auth().signOut().then(() => {
      this.props.history.push('/')
    })
  }

  /*
  displayNextStepRoute() {
    console.log("CURRENT USER IN PATIENT", this.props.currentUser)
    let routeToDisplay = this.props.currentUser.nextRouteToDisplay()
    console.log("change route to", routeToDisplay)
    this.props.history.push(routeToDisplay, { validAction: userRegisteredInfoValidationAction.NEXT_STEP })
  }
  */

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate displaystepper", prevProps.displayStepper, this.props.displayStepper )
    if(prevProps.displayStepper != this.props.displayStepper){
      console.log("DISPLAY STEPPPEEEEEEEEEEEEERRRRRRRRRRRRRRRRRRRRRRRRRRR")
      this.props.history.push("/patient/room")
    }
  }

  componentWillReceiveProps(nextProps:PatientProps) {

 
  }

  

  componentDidMount() {

    if(this.props.displayStepper == true){
      this.props.history.push("/patient/operationDate") // after code register is OK => display the first step of onboarding stepper
    }else{  
      this.props.history.push("/patient/state") 
   }
  }



  public render() {
    return (
      
        <Switch>
          {/* after register stepper */}
          <Route  exact path="/patient/operationDate">
            <OperationDatePage skip={true} goNext={"/patient/relations" } />
          </Route>
          <Route  exact path="/patient/relations">
            <RelationsPage skip={true} goNext={"/patient/install" } />
          </Route>

          <Route  exact path="/patient/install">
            <Install goNext={"/patient/state" } />
          </Route>

        {/* main page when logged */}
          <Route  exact path="/patient/state">
            <PatientStatePage  />
          </Route>

            <Route  exact path="/patient/exit">
            <ExitPage  />
          </Route>        

        {/* configuration pages */}
          <Route  exact path="/patient/configuration/operationDate">
            <OperationDatePage displayBack={true} goNext={"/patient/configuration"  } />
          </Route>
          <Route  exact path="/patient/configuration/relations">
            <RelationsPage displayBack={true} goNext={"/patient/configuration" } />
          </Route>

          <Route  exact path="/patient/configuration/">
            <PatientConfiguration/>
          </Route>


        </Switch>
    

    );
  }
}

/*
 <div>
        <div>PATIENT</div>




        <Button onClick={() => { this.logout() }} variant="contained" color="primary">
          DÉCONNECTER
      </Button>
      </div>
*/

const mapDispatchToProps = dispatch => ({

})

const mapStateToProps = state => ({
    currentUser: state.userReducer.currentUser,
    displayStepper: state.userReducer.displayMoreInfoStepper
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(Patient)));


