import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationFR from './translations/fr.json';
import translationEN from './translations/en.json';

export const DefaultLocale = "fr";

// the translations
const resources = {
  fr: {
    translation: translationFR
  },
  en: {
    translation: translationEN
  }
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: DefaultLocale,
    saveMissing: true,
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;