import { Button, Container, Divider, Icon, LinearProgress, Typography } from '@material-ui/core';

import React from 'react'

import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import PregnantWomanIcon from '@material-ui/icons/PregnantWoman';

import { User } from '../../model/user';
import { setGenre } from '../../actions/user/user.action'
import { ReactReduxContext, connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
    BrowserRouter as Router,
    withRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";

export enum GenreEnum {
    MALE,
    FEMALE,
    NO
}

interface GenreProps {
    router: any;
    user: User;
    setGenre: (genre: GenreEnum) => void;
    history: any; //react router
    t: any;
    i18n: any
}

interface GenreState {
    selectedGenre: GenreEnum;
}

class Genre extends React.Component<GenreProps, GenreState> {

    constructor(props:GenreProps) {
        super(props)

        this.state = {
            selectedGenre: this.props.user.genre != undefined ? this.props.user.genre : GenreEnum.NO
        }

        this.props.setGenre(this.state.selectedGenre)
    }

    componentDidMount() {
        console.log("genre did mount")
    }

    onGenreSelected = (genreSelected: GenreEnum) => {
        this.setState({ selectedGenre: genreSelected })

        this.props.setGenre(genreSelected)
    }

    continue = () => {

        let user = new User();
        user.genre = this.state.selectedGenre;
        this.props.history.push('/onboarding/infos', { userJson: JSON.stringify(user) })
    }

    //this.state.selectedGenre == GenreEnum.MALE ? "blue" : "gray" 
    render() {
        console.log("genre render")
        return (
            <div>
                <Container maxWidth="sm">
                    <LinearProgress variant="determinate" value={33} />

                    <Typography style={{textAlign: "center", marginTop: 30}}>
                    {this.props.t('Select genre')}                        
                    </Typography>


                    <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 40 }}>
                        <div style={{ alignSelf: "center", width: "50%" }} onClick={() => this.onGenreSelected(GenreEnum.MALE)} >
                            <img style={{ filter: this.state.selectedGenre == GenreEnum.MALE ? "grayscale(0)" : "grayscale(1)",  width: "80%", display: "block", margin: "auto" }} src="/img/male.png"></img>                            
                        </div>

                        <div style={{ alignSelf: "center",  width: "50%" }} onClick={() => this.onGenreSelected(GenreEnum.FEMALE)}>
                        <img style={{  filter: this.state.selectedGenre == GenreEnum.FEMALE ? "grayscale(0)" : "grayscale(1)", width: "80%", display: "block", margin: "auto" }} src="/img/female.png"></img>
                        </div>
                    </div>


                    { this.state.selectedGenre != GenreEnum.NO &&
                    <Button style={{ display: "block", margin: "auto", marginTop: 40 }} onClick={() => { this.continue() }} variant="contained" color="primary">
                        {this.props.t('Continue')}                        
                 </Button>
    }


                </Container>
            </div>
        )

    }
}

const mapDispatchToProps = dispatch => ({
    setGenre: (genre: GenreEnum) => dispatch(setGenre(genre))
})

const mapStateToProps = state => ({
    user: state.userReducer.user
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Genre)));