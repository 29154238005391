import { AppBar, Button, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';

import { connect } from 'react-redux';
import { setCurrentUser } from '../../actions/user/user.action';
import { User } from '../../model/user';
import { withSnackbar } from 'notistack';
import { firestoreDb } from '../../firebase';
import { JsonUtils } from '../../Utils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { DatePicker } from "@material-ui/pickers"
import QueryBuilderTwoToneIcon from '@material-ui/icons/QueryBuilderTwoTone';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { SoftButton } from '../../components/SoftButton';


export interface OperationDatePageProps {
  setCurrentUser: (currentUser: User) => void
  currentUser: User;
  enqueueSnackbar: (mesg: string, options: any) => void;
  displayBack: boolean;
  history: any;
  goNext: string;
  skip: boolean;
  t: any;
  i18n: any;
  location:any;
  match: any;
}

export interface OperationDatePageState {
  operationDate: Date | undefined;
}

class OperationDatePage extends React.Component<OperationDatePageProps, any> {

  static defaultProps = {
    skip: false
  }

  constructor(props) {
    super(props)
    this.state = {
      operationDate: undefined
    }

  }





  next = () => {

    let me = this;
    let user: User = me.props.currentUser;

    if (this.state.operationDate == undefined) {
      this.props.enqueueSnackbar(me.props.t('Date undefined'), { variant: 'error', autoHideDuration: 2000 });
    }

    console.log("update operation date", new Date(this.state.operationDate))

    firestoreDb.collection('user').doc(user.id).update({
      operationDate: new Date(this.state.operationDate)
    })
      .then(function () {

        user.operationDate = new Date(me.state.operationDate)

        console.log("new date registered at backend", user.operationDate)
        me.props.setCurrentUser(user) // dispatch      
        me.props.enqueueSnackbar(me.props.t('Operation date updated'), { variant: 'success', autoHideDuration: 2000 });

        // override props
        if (me.props.location && me.props.location.state && me.props.location.state.goNext != undefined) {
          console.log("override goNext props from router param")
          me.props.history.push(me.props.location.state.goNext)
        }else{
          me.props.history.push(me.props.goNext)
        }

      }).catch(function (error) {
        // The document probably doesn't exist.                
        me.props.enqueueSnackbar(me.props.t('Error on update'), { variant: 'error', autoHideDuration: 2000 });
        console.error("Error updating document: ", error);
      });


  }

  onOperationDateChange = (value) => {
    console.log("update date : ", value)
    this.setState({ operationDate: value, defaultValue: value })
  }

  componentDidMount() {
    let format = "YYYY-MM-DD"
    let defaultValue = (this.props.currentUser && this.props.currentUser.operationDate) ? moment(this.props.currentUser.operationDate).format(format) : moment().format(format)
    console.log("default value", defaultValue, " from current user", this.props.currentUser.operationDate)
    this.setState({ defaultValue: defaultValue })
  }

  setDateToday = () => {
    let format = "YYYY-MM-DD"
    let todayMomentDate = moment();
    let todayDateFormated = todayMomentDate.format(format)
    let todayDate = todayMomentDate.toDate()
    this.setState({ operationDate: todayDate, defaultValue: todayDateFormated }, () => {
      // then directly save
      this.next()
    })
    
  }


  render() {

    return (
      <div>


        {this.props.displayBack == true &&
          <AppBar position="static">
            <Toolbar>
              <IconButton onClick={this.props.history.goBack} edge="start" color="inherit" aria-label="menu">
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" >
                {this.props.t('Your operation date')}
              </Typography>
            </Toolbar>
          </AppBar>
        }

              
       


        <QueryBuilderTwoToneIcon color='primary' style={{ fontSize: 120, margin: "auto", display: "block", marginTop: 40 }} color="primary"></QueryBuilderTwoToneIcon>


                  <Button style={{ display: "block", margin: "auto", marginTop: 40 }} onClick={this.setDateToday} variant="contained" color="primary">
            {this.props.t('Today operation date')}
          </Button>

        <Typography style={{textAlign: "center", marginTop: 30}} variant="h6">ou</Typography>

        {this.state.defaultValue &&

          <DatePicker
            style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "70%", marginTop: 40 }}
            disablePast={true}
            disableFuture={false}
            showTodayButton={false}
            todayLabel={this.props.t('Today')}
            //variant={'static'}
            //DialogProps={{ fullScreen: true }}
            openTo="year"
            cancelLabel={this.props.t('Cancel')}
            //format="DD MM yyyy"
            labelFunc={(date, invalidLabel) => { 
              if(this.props.currentUser.operationDate){
                return moment(date).format("Do MMMM YYYY")
              }else{
                return this.props.t('No date')
              }
             }}
            label={this.props.t('Input your operation date')}
            views={["year", "month", "date"]}
            value={this.state.defaultValue}
            onChange={(value) => this.onOperationDateChange(value)}
          />



        }

 


        { this.state.operationDate != undefined &&
          <Button style={{ display: "block", margin: "auto", marginTop: 40 }} onClick={this.next} variant="contained" color="secondary">
            {this.props.t('Save')}
          </Button>
        }


        {this.props.skip == true &&
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <SoftButton color="default" onClick={() => { this.props.history.push(this.props.goNext) }}
              style={{
                display: "block", textAlign: "center", margin: "auto",
                bottom: 15, position: "absolute"
              }} variant="contained" color="primary">
              {this.props.t('Skip this step')}
            </SoftButton>
          </div>
        }


      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.userReducer.currentUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentUser: (currentUser: User) => dispatch(setCurrentUser(currentUser))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(withTranslation()(OperationDatePage))));
